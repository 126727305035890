import React from 'react'
import './CSS/PrivacyPolicies.css'
import { Link } from 'react-router-dom'

const TermsOfService = () => {
    return (
        <div className='policies'>
            <div className="container">
                <h1>Terms Of Service</h1>
                <section className='text-centre'>
                    <h2>Welcome to Wellness Copper.</h2>
                    <p>These Terms of Service govern your use of our website, products, and services. By accessing or using our services, you agree to abide by these terms.</p>
                </section>

                <section>
                    <h2>1. Acceptance of Terms:</h2>
                    <p>1.1. By accessing or using our services, you agree to comply with these Terms of Service.</p>
                    <p>1.2. If you do not agree with any part of these terms, you may not use our services.</p>
                </section>

                <section>
                    <h2>2. Use of Services:</h2>
                    <p>2.1. You agree to use our services for lawful purposes and in a manner consistent with all applicable local, state, national, and international laws and regulations.</p>
                    <p>2.2. You may not use our services in any manner that could damage, disable, overburden, or impair our website or interfere with any other party's use and enjoyment of our services.</p>
                </section>

                <section>
                    <h2>3. User Accounts:</h2>
                    <p>3.1. If you create an account with us, you are responsible for maintaining the confidentiality of your account information and password.</p>
                    <p>3.2. You agree to accept responsibility for all activities that occur under your account.</p>
                </section>

                <section>
                    <h2>4. Intellectual Property:</h2>
                    <p>4.1. All content on our website, including but not limited to text, graphics, logos, images, and software, is the property of Wellness Copper and is protected by intellectual property laws.</p>
                    <p>4.2. You may not modify, reproduce, distribute, or create derivative works based on our content without our express consent.</p>
                </section>

                <section>
                    <h2>5. Privacy:</h2>
                    <p>5.1. Your use of our services is also governed by our Privacy Policy. Please review the policy to understand our practices regarding the collection and use of your personal information.</p>
                </section>

                <section>
                    <h2>6. Disclaimer of Warranties:</h2>
                    <p>6.1. Our services are provided "as is" and "as available" without any warranties, express or implied.</p>
                    <p>6.2. We do not warrant that our services will be uninterrupted, secure, or error-free.</p>
                </section>

                <section>
                    <h2>7. Limitation of Liability:</h2>
                    <p>7.1. Wellness Copper shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>
                    <p>7.2. In no event shall our total liability to you for all damages exceed the amount paid by you for our services.</p>
                </section>

                <section>
                    <h2>8. Governing Law:</h2>
                    <p>8.1. These Terms of Service shall be governed by and construed in accordance with the laws of [Your Jurisdiction].</p>
                </section>

                <section>
                    <h2>9. Changes to Terms:</h2>
                    <p>9.1. We reserve the right to modify or replace these Terms of Service at any time. Changes will be effective upon posting.</p>
                    <p>9.2. Your continued use of our services after any changes to these terms constitutes acceptance of those changes.</p>
                </section>
            </div>

            <section>
                <p>For any further questions regarding our Privacy Policy, please contact us at <Link to={'mailto:help@wellnesscopper.com'}>help@wellnesscopper.com</Link>.</p>
                <p>Thank you for choosing Wellness Copper.</p> <br />
                <p>
                    <b>AVONTIK INTERNATIONAL LLP</b> <br />
                    <b>INDIA</b> <br />
                    <Link to={'mailto:help@wellnesscopper.com'}>help@wellnesscopper.com</Link> <br />
                    <Link to={'tel:+91 8827512215'}>+91 8827512215</Link>
                </p>
            </section>
        </div>
    )
}

export default TermsOfService
