// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myAccount{
    margin: 40px auto;
}

.myAccount .container{
    padding: 20px 0;
    text-align: left;
}

.myAccount .container p{
    width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/pages/CSS/MyAccount.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".myAccount{\n    margin: 40px auto;\n}\n\n.myAccount .container{\n    padding: 20px 0;\n    text-align: left;\n}\n\n.myAccount .container p{\n    width: 70%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
