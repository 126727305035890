let data_product = [
  {
    id: 1,
    name: "Abstract White",
    image: "Abstract_White_Listing_1.jpg",
    image_1: "Abstract_White_Listing_2.jpg",
    image_2: "Abstract_White_Listing_3.jpg",
    image_3: "Abstract_White_Listing_4.jpg",
    image_4: "Abstract_White_Listing_5.jpg",
    category: "Copper Bottle",
  capacity: 950,
    rating: "4.7",
    new_price: 749,
    old_price: 1200,
    stock: 10,
    description: "Abstract White Elegance by WellnessCopper  Indulge in the artistry of wellness with our Abstract White design – a harmonious blend of modern sophistication and timeless simplicity. Crafted by WellnessCopper, this exquisite copper bottle transcends the ordinary, featuring a captivating abstract pattern in pristine white. Each sip becomes a moment of wellness, marrying the potential health benefits of copper with the elegance of contemporary design. Elevate your hydration experience with a touch of artistic flair. WellnessCopper - where design meets well-being.",
    "quntity": "",
    sku: "WS01AI0100",
    discount: ""
  },
  {
    id: 2,
    name: "Aqua Batik Geometirc",
    image: "Aqua_Batik_Geometirc_Listing_1.jpg",
    image_1: "Aqua_Batik_Geometirc_Listing_2.jpg",
    image_2: "Aqua_Batik_Geometirc_Listing_3.jpg",
    image_3: "Aqua_Batik_Geometirc_Listing_4.jpg",
    image_4: "Aqua_Batik_Geometirc_Listing_5.jpg",
    category: "Copper Bottle",
  capacity: 950,
    rating: "4.6",
    new_price: 749,
    old_price: 1200,
    stock: 7,
    description: "Aqua Batik Geometry: Dive into Wellness with Style  Immerse yourself in the serenity of Aqua Batik Geometry, a captivating design by WellnessCopper. Inspired by the fluidity of batik patterns and the precision of geometric forms, this copper bottle is a testament to the fusion of art and wellness. The soothing aqua hues evoke a sense of calm, making each sip a rejuvenating experience. Embrace the elegance of tradition and the modern aesthetic in every drop. Elevate your hydration with WellnessCopper – where design becomes a celebration of well-being.",
    "quntity": "",
    sku: "WS01AI0101",
    discount: ""
  },
  {
    id: 3,
    name: "Black Gold Moroccan",
    image: "Black_Gold_Moroccan_Listing_1.jpg",
    image_1: "Black_Gold_Moroccan_Listing_2.jpg",
    image_2: "Black_Gold_Moroccan_Listing_3.jpg",
    image_3: "Black_Gold_Moroccan_Listing_4.jpg",
    image_4: "Black_Gold_Moroccan_Listing_5.jpg",
    category: "Copper Bottle",
  capacity: 950,
    rating: "4.8",
    new_price: 749,
    old_price: 1200,
    stock: 7,
    description: "Black Gold Moroccan Elegance: Unveil the Luxe of Wellness  Step into opulence with our Black Gold Moroccan design, a masterpiece from WellnessCopper. Inspired by the intricate beauty of Moroccan artistry, this copper bottle boasts a blend of ebony and gold, creating an aura of timeless sophistication. Every sip from this bottle is a journey into indulgence, combining the potential health benefits of copper with the allure of luxurious design. Elevate your hydration experience and make a statement with the epitome of WellnessCopper elegance.",
    "quntity": "",
    sku: "WS01AI0102",
    discount: ""
  },
  {
    id: 14,
    name: "Modern Art",
    image: "Modern_Art_Listing_1.jpg",
    image_1: "Modern_Art_Listing_2.jpg",
    image_2: "Modern_Art_Listing_3.jpg",
    image_3: "Modern_Art_Listing_4.jpg",
    image_4: "Modern_Art_Listing_5.jpg",
    category: "Copper Bottle",
  capacity: 950,
    rating: "4.8",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description: "Modern Artistry: Wellness Redefined  Indulge in the avant-garde elegance of our Modern Art design at WellnessCopper. This copper bottle transcends the ordinary, transforming hydration into a canvas of contemporary expression. Featuring bold strokes, dynamic lines, and vibrant hues, each sip becomes a masterpiece of well-being. Elevate your style, elevate your health with the cutting-edge design of Modern Art by WellnessCopper. ",
    "quntity": "",
    sku: "WS01AI0113",
    discount: ""
  }
];

export default data_product;
