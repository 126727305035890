// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.failure{
    margin-top: 80px;
    margin-bottom: 120px;
}

.failure img{
    width: 300px;
    margin-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/failure/Failure.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".failure{\n    margin-top: 80px;\n    margin-bottom: 120px;\n}\n\n.failure img{\n    width: 300px;\n    margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
