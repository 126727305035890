import React, { useContext, useState } from 'react'
import { ShopContext } from '../../context/ShopContext'
import { Link } from 'react-router-dom'
import './ItemCart.css'
const ItemCart = () => {
    const [couponValue, setCouponValue] = useState('');
    const { all_product, cartItems, addToCart, removeFromCart, removeAllFromCart, getTotalCartAmount, checkPromo, getDiscount, getTotalAmount, isLoggedIn } = useContext(ShopContext);

    console.log("Logged In",isLoggedIn);
    var discountData = getDiscount();

    console.log(getDiscount()[0])
    const handleInputChange = (e) => {
        setCouponValue(e.target.value);
        discountData = getDiscount();
    };

    const checkPromoCode = () => {
        checkPromo(couponValue);
    };

    // const checkout = () => {
    
    //     if (!isLoggedIn && false){
    //         window.location.href = '/login';
    //     }else{
    //         window.location.href = '/checkout';
    //     }
    //     }

  return (
    <div>
      <div className="container cart-container">
        <div className="title">
            <h3>Order Review</h3>
        </div>
        <div className="cart-items">
        {all_product.map((e, i) => {
                if (cartItems[e.id] > 0) {
                    return <div className="cart-item" key={i}>
                    <div className="image">
                        <img src={`/assets/copper-bottels/${e.id}/${e.image}`} alt="" />
                    </div>
                    <div className="description">
                        <div className="name">
                            <h4>{e.name}</h4>
                            <button onClick={() => { removeAllFromCart(e.id) }} >Remove</button>
                        </div>
                        <div className="amount-qty">
                            <div className="qty">
                                <button onClick={() => { removeFromCart(e.id) }} >-</button>
                                <span> {cartItems[e.id]} </span>
                                <button onClick={() => { addToCart(e.id) }} >+</button>
                            </div>
                            <div className="amount">
                                <span>₹{e.new_price * cartItems[e.id]}</span>
                            </div>
                        </div>
    
                    </div>
                </div>
                } else {
                    return null
                }
            })}
        </div>
        <div className="bill-discount">
            <div className="bill">
                <div className="subtotal">
                    <p>Subtotal</p>
                    <p>₹{getTotalCartAmount()}</p>
                </div>
                <div className="taxes">
                    <p>Taxes</p>
                    <p>₹0</p>
                </div>
                <div className="shipping">
                    <p>Shipping</p>
                    <p>₹0</p>
                </div>
                {(discountData[0] > 0 ? <> <div className="discount-amt">
                            <p>Discount</p>
                            <p>₹{(getTotalCartAmount() - getTotalAmount())}</p>
                        </div></> : '')}
                <hr />
                <div className="total">
                    <p>Total</p>
                    <p>₹{getTotalAmount()}</p>
                </div>
                <div className="discount">
                    <input type="text" placeholder='Discount code' onChange={handleInputChange}/>
                    <button onClick={checkPromoCode}>Apply</button>
                </div>
                {(discountData[0] === -1) ? <span className='cuponError'>{discountData[1]}</span> : <span>{discountData[1]}</span>}

               <div className="checkout-btn">
                {/* <Link to='/checkout'><button disabled={(Math.floor(getTotalAmount())===0)}>Checkout</button></Link> */}
                <Link to="/checkout"><button disabled={(Math.floor(getTotalAmount())===0)}>Checkout</button></Link>
               </div>
               <div className="seprator">
                <hr />
                <span>or</span>
                <hr />
               </div>
               <div className="continue-btn">
                <Link to='/shop'><button>Continue shopping</button></Link>
               </div>
            </div>
        </div>

      </div>
    </div>
  )
}

export default ItemCart
