import React, { useContext, useState } from 'react'
import './Checkout.css'
import { ShopContext } from '../../context/ShopContext'
import loadIcon from '../assets/loading.gif'

const Checkout = () => {
    const { all_product, cartItems, getTotalCartAmount, getTotalAmount, getDiscount } = useContext(ShopContext);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    // Use map to create an object
    const mappedObject = {}
    all_product.forEach(item => {
        if (cartItems[item.id] > 0) {
            mappedObject[item.name] = cartItems[item.id];
        }
      });

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        pincode: '',
        countery: 'India', // Assuming default countery is India
        amount: getTotalAmount(), // Get total cart amount
        orderDetails: mappedObject
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {
        const [errors, errorState] = validate(formData)
        setFormErrors(errors);
        // setFormErrors(validate(formData));
        if (errorState) {
            setLoading(true);
            window.scrollTo(0, 0)
            document.body.style.overflow = 'hidden';
            const apiUrl = process.env.REACT_APP_BACKEND_API_ENDPOINT + 'add_address/';
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });
                const data = await response.json();

                if (response.ok) {
                    // Handle success, maybe redirect or show a success message
                    console.log(data)
                    window.location.href = data.redirect_url;
                } else {
                    // Handle error, maybe show an error message
                    setLoading(false);
                    document.body.style.overflow = '';
                    console.error("Something went wrong!!!")
                }
            } catch (error) {
                // Handle network errors
                setLoading(false);
                document.body.style.overflow = '';
                console.error('Error:', error);
            }
        }

    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.firstName) {
            errors.firstName = "First name is required!";
        }
        if (!values.lastName) {
            errors.lastName = "Last name is required!";
        }
        if (!values.address1) {
            errors.address1 = "Address is required!";
        }
        if (!values.city) {
            errors.city = "City is required!";
        }
        if (!values.state) {
            errors.state = "State is required!";
        }
        if (!values.pincode) {
            errors.pincode = "Pincode is required!";
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = "Phone number is required!";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        return [errors, (Object.keys(errors).length === 0)];
    };

    return (
        <div>
            <div className={loading ? 'loading visible' : 'loading'}>
                {/* <div className='loading'> */}
                <div className="loading-box">
                    <img src={loadIcon} alt="" width={100} />
                </div>
            </div>
            <div className="checkout container">
                <div className="info">
                    <h2>Contact Information</h2>
                    <div className="email field">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="" placeholder='' onChange={handleInputChange} />
                        <span className='text-danger'>{formErrors.email}</span>
                    </div>
                    <div className='divider'></div>
                    <h2>Shipping Information</h2>
                    <div className="fullname field">
                        <div className="fname">
                            <label htmlFor="firstName">First name</label>
                            <input type="text" name="firstName" id="" placeholder='' onChange={handleInputChange} required />
                            <span className='text-danger'>{formErrors.firstName}</span>
                        </div>
                        <div className="lname">
                            <label htmlFor="lastName">Last name</label>
                            <input type="text" name="lastName" id="" placeholder='' onChange={handleInputChange} required />
                            <span className='text-danger'>{formErrors.lastName}</span>
                        </div>
                    </div>
                    <div className="number field">
                        <label htmlFor="lastName">Phone number</label>
                        <input type="phone" name="phoneNumber" id="" placeholder='' onChange={handleInputChange} />
                        <span className='text-danger'>{formErrors.phoneNumber}</span>
                    </div>
                    <div className="address field">
                        <label htmlFor="address">Address</label>
                        <input type="text" name="address1" id="" placeholder='' onChange={handleInputChange} required />
                        <span className='text-danger'>{formErrors.address1}</span>
                    </div>
                    <div className="address field">
                        <label htmlFor="address">Address (optional)</label>
                        <input type="text" name="address2" id="" placeholder='' onChange={handleInputChange} />
                        <span className='text-danger'>{ }</span>
                    </div>
                    <div className="city-state-zip field">
                        <div className="city">
                            <label htmlFor="lastName">City</label>
                            <input type="text" name="city" id="" placeholder='' onChange={handleInputChange} />
                            <span className='text-danger'>{formErrors.city}</span>
                        </div>
                        <div className="state">
                            <label htmlFor="state">State</label>
                            {/* <input type="text" name="state" id="" placeholder='' onChange={handleInputChange} /> */}
                            <select className="form-control" id="state" name='state' onChange={handleInputChange}>
                                <option value="">Select State</option>
                                <option value="Andra Pradesh">Andra Pradesh</option>
                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chhattisgarh">Chhattisgarh</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Madya Pradesh">Madya Pradesh</option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Orissa">Orissa</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttaranchal">Uttaranchal</option>
                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                <option value="West Bengal">West Bengal</option>
                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                <option value="Daman and Diu">Daman and Diu</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Lakshadeep">Lakshadeep</option>
                                <option value="Pondicherry">Pondicherry</option>
                            </select>
                            <span className='text-danger'>{formErrors.state}</span>
                        </div>
                        <div className="pincode">
                            <label htmlFor="lastName">Pincode</label>
                            <input type="number" min='111111' max='999999' maxLength='6' name="pincode" id="" placeholder='' onChange={handleInputChange} />
                            <span className='text-danger'>{formErrors.pincode}</span>

                        </div>
                    </div>
                    <div className="countery field">
                        <label htmlFor="lastName">Country</label>
                        <input type="text" name="countery" id="" placeholder='Countery' value='India' disabled />
                    </div>
                    {/* <div className="amount field">
                        <label htmlFor="lastName">Amount</label>
                        <input type="text" name="amount" id="" value={'₹ ' + getTotalCartAmount()} disabled />
                    </div> */}
                </div>
                <div className="items-bill">
                    <h2>Order Summary</h2>
                    <div className="items-list">

                        {all_product.map((e, i) => {
                            if (cartItems[e.id] > 0) {
                                return <div className="item-box" key={i}>
                                    <img src={`/assets/copper-bottels/${e.id}/${e.image}`} alt="" />
                                    <div className="item-content">
                                        <h4>{e.name}</h4>
                                        <span>₹{e.new_price}</span>
                                    </div>
                                </div>
                            } else {
                                return null
                            }
                        })}
                    </div>
                    <div className="bill">
                        <div className="subtotal">
                            <div className="tag">Subtotal</div>
                            <div className="amount">₹ {getTotalCartAmount()}</div>
                        </div>
                        <div className="subtotal">
                            <div className="tag">Shipping</div>
                            <div className="amount">₹ 0</div>
                        </div>
                        {getDiscount() === -1 ? '' : <div className="subtotal">
                            <div className="tag">Discounts</div>
                            <div className="amount">₹ {(getTotalCartAmount() - getTotalAmount())}</div>
                        </div>}
                        <div className="divider"></div>
                        <div className="subtotal">
                            <div className="tag"><b>Total</b></div>
                            <div className="amount"><b>₹ {getTotalAmount()}</b></div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <button onClick={handleSubmit}>Confirm Order</button>
                </div>
            </div>
        </div>
    )
}

export default Checkout
