import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PaymentStatus = () => {
    const { merchantId } = useParams();
    const apiEndpoint = process.env.REACT_APP_BACKEND_API_ENDPOINT + 'check_status/' + merchantId + '/';

    useEffect(() => {
        fetch(apiEndpoint)
           .then((response) => response.json())
           .then((data) => {
              console.log(data);
              window.location.href = data.url;
           })
           .catch((err) => {
              console.log(err.message);
           });
     }, [apiEndpoint]);

    return (
        <div>
            {/* Your component JSX */}
            <h2>Payment Status</h2>

        </div>
    );
};

export default PaymentStatus;
