// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popular{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    min-height: 90vh;
}

.popular h1{
    color: #171717;
    font-size: 34px;
    font-weight: 600;
}

.popular hr{
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background-color: #252525;

}

.popular-item{
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px){

}

/*  */
@media only screen and (max-width: 992px){
    .popular-item{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px){
    .popular-item{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px){
    .popular-item{
        display: grid;
        grid-template-columns: 1fr;
    }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px){

}


`, "",{"version":3,"sources":["webpack://./src/components/popular/Popular.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,yBAAyB;;AAE7B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sCAAsC;IACtC,SAAS;AACb;;AAEA,oEAAoE;AACpE;;AAEA;;AAEA,KAAK;AACL;IACI;QACI,aAAa;QACb,kCAAkC;IACtC;AACJ;;;AAGA,qDAAqD;AACrD;IACI;QACI,aAAa;QACb,8BAA8B;IAClC;AACJ;;;AAGA,oEAAoE;AACpE;IACI;QACI,aAAa;QACb,0BAA0B;IAC9B;;AAEJ;;AAEA,iDAAiD;AACjD;;AAEA","sourcesContent":[".popular{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n    min-height: 90vh;\n}\n\n.popular h1{\n    color: #171717;\n    font-size: 34px;\n    font-weight: 600;\n}\n\n.popular hr{\n    width: 200px;\n    height: 6px;\n    border-radius: 10px;\n    background-color: #252525;\n\n}\n\n.popular-item{\n    margin-top: 50px;\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    gap: 30px;\n}\n\n/* Extra large devices (large laptops and desktops, 1200px and up) */\n@media only screen and (min-width: 1200px){\n\n}\n\n/*  */\n@media only screen and (max-width: 992px){\n    .popular-item{\n        display: grid;\n        grid-template-columns: 1fr 1fr 1fr;\n    }\n}\n\n\n/* Medium devices (landscape tablets, 768px and up) */\n@media only screen and (max-width: 768px){\n    .popular-item{\n        display: grid;\n        grid-template-columns: 1fr 1fr;\n    }\n}\n\n\n/* Small devices (portrait tablets and large phones, 600px and up) */\n@media only screen and (max-width: 600px){\n    .popular-item{\n        display: grid;\n        grid-template-columns: 1fr;\n    }\n\n}\n\n/* Extra small devices (phones, 600px and down) */\n@media only screen and (max-width: 600px){\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
