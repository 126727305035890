import React, { useContext } from 'react'
import './CSS/ShopCategory.css'
import { ShopContext } from '../context/ShopContext'
import dropdown_icon from '../components/assets/dropdown_icon.png'
import Item2 from '../components/Item2/Item2'

const ShopCategory = (props) => {
    const {all_product} = useContext(ShopContext)
  return (
    <div className='shop-category'>
      <img src={props.banner} alt="" />
      <div className="shopcategory-indexSort">
        <p>
            <span>Showing 1-{all_product.length}</span> out of {all_product.length} products
        </p>
        <div className="shopcategory-sort" hidden>
            Sort by <img src={dropdown_icon} alt="" />
        </div>
      </div>
      <div className="shopcategory-products">
        {all_product?.map((item, i)=>{
            if(props.category===item.category){
                return <Item2 key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} category={item.category} stock={item.stock} rating={item.rating}/>
            }
            else{
                return null;
            }
        })}
      </div>
      {/* <div className="shopcategory-loadmore">
        Explore More
      </div> */}
    </div>
  )
}

export default ShopCategory
