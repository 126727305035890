import React, { useEffect } from 'react'
import './Success.css'
import success_icon from '../assets/success_green.png'

const Success = () => {
  useEffect(() => {
    // Set a timeout to show the message after 5 seconds
    console.log("Working...")
    const timeoutId = setTimeout(() => {
      window.location.replace('/shop')
    }, 5000);

    // Clear the timeout if the component unmounts or if you want to cancel it for any reason
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  return (
    <div className='success'>
        <h2>Thank You!</h2>
        <img src={success_icon} alt="" />
        <h4>Your Order is successfully placed, you will get the confirmation on your email shortly</h4>
      
    </div>
  )
}

export default Success
