import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/AboutUs.css'
import About from '../components/about/About'
import MessageBanner from '../components/messageBanner/MessageBanner'
import yash from '../components/assets/yash.jpg'
import prasoon from '../components/assets/prasoon.jpg'
import copper_benefits from '../components/assets/copper_benifits.jpg'

const AboutUs = () => {
    return (
        <div>
            <div className="about-page header">
                <h2>About Us</h2>
            </div>
            <About />
            <div className="container">
                <div className='benefit-copper'>
                    <div className="image">
                        <img src={copper_benefits} alt="" /> 
                    </div>
                    <div className="content">
                        <h3>The Benefits of Copper Bottles</h3>
                        <p>Incorporating copper bottles into your lifestyle isn't merely a trend; it's a timeless tradition rooted in holistic wellness. From bolstering immunity to promoting youthful vitality, the benefits of copper extend far beyond mere hydration. Embrace the wisdom of ancient practices and embark on a journey towards optimal health with copper bottles as your trusted companions. Experience the transformative power of copper and elevate your well-being one sip at a time.</p>

                        <p>As we navigate the complexities of modern life, let us not forget the timeless remedies gifted to us by our ancestors. Embrace the embrace of copper, honor the sanctity of your well-being, and embark on a journey towards a healthier, more vibrant existence. With each sip from your copper vessel, may you be reminded of the profound connection between nature and nourishment, and may your wellness journey be as enduring as the metal itself.</p>
                        <p>
                        Beyond mere hydration, copper bottles offer a gateway to a life infused with vitality and resilience. As we stand at the crossroads of convenience and conscientious living, let us choose the path that honors our bodies and the planet. Join the movement towards holistic wellness, and let the luster of copper illuminate your journey to optimal health. With every sip, may you not only nourish your body but also nurture a deeper connection to the timeless rhythms of nature. Choose copper, choose wellness, choose a future where health and harmony reign supreme.
                        </p>
                        <Link to='/shop'><button>Shop Now</button></Link>
                    </div>

                </div>
            </div>
            <MessageBanner text={'Together, we can make a difference in the world, one sip at a time.'} />
            <div className="team container">
                <div className="team-members">
                    <div className="member-box">
                        <div className="image">
                            <img src={yash} alt="" />
                        </div>
                        <div className="content">
                            <h3>Meet Our Visionary Founder: Yash Nagar</h3>
                            <p>Yash Nagar, the visionary force behind Wellness Copper is a testament to the fusion of innovation and tradition. With a background Engineering, Yash embarked on a journey fueled by a profound passion for holistic wellness and a deep reverence for ancient wisdom. His innovative spirit and unwavering commitment to excellence have established the Company as a beacon of wellness, inspiring individuals to embrace a lifestyle rooted in vitality and balance.</p>
                        </div>
                    </div>
                    <div className="member-box">
                        <div className="image">
                            <img src={prasoon} alt="" />
                        </div>
                        <div className="content">
                            <h3>Meet Our CEO : Prasoon Patel </h3>
                            <p>At the Center of Wellness Copper, our CEO stands as a beacon of leadership, intertwining the visionary principles of our founder with a specialized expertise in marketing. With a profound commitment to holistic wellness, our CEO champions our brand's mission, ensuring that every strategy and decision reflects our core values.Together with founder Yash Nagar, our CEO epitomizes the spirit of the Company, leading us towards a future where wellness is not just a goal but a way of life. With their leadership and marketing mastery, we are poised to make an enduring impact on the world, enriching lives one sip at a time.</p>
                        </div>
                    </div>
                </div>

            </div>
        
        </div>
    )
}

export default AboutUs
