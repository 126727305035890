
let new_collections = [
//   {
//     id: 1,
//     name: "Abstract White",
//     image: "Abstract_White_Listing_1.jpg",
//     image_1: "Abstract_White_Listing_2.jpg",
//     image_2: "Abstract_White_Listing_3.jpg",
//     image_3: "Abstract_White_Listing_4.jpg",
//     image_4: "Abstract_White_Listing_5.jpg",
//     category: "Copper Bottle",
// capacity: 950,
//     rating: "4.7",
//     new_price: 749,
//     old_price: 1200,
//     stock: 10,
//     description: "Abstract White Elegance by WellnessCopper  Indulge in the artistry of wellness with our Abstract White design – a harmonious blend of modern sophistication and timeless simplicity. Crafted by WellnessCopper, this exquisite copper bottle transcends the ordinary, featuring a captivating abstract pattern in pristine white. Each sip becomes a moment of wellness, marrying the potential health benefits of copper with the elegance of contemporary design. Elevate your hydration experience with a touch of artistic flair. WellnessCopper - where design meets well-being.",
//     "quntity": "",
//     sku: "WS01AI0100",
//     discount: ""
//   },
//   {
//     id: 2,
//     name: "Aqua Batik Geometirc",
//     image: "Aqua_Batik_Geometirc_Listing_1.jpg",
//     image_1: "Aqua_Batik_Geometirc_Listing_2.jpg",
//     image_2: "Aqua_Batik_Geometirc_Listing_3.jpg",
//     image_3: "Aqua_Batik_Geometirc_Listing_4.jpg",
//     image_4: "Aqua_Batik_Geometirc_Listing_5.jpg",
//     category: "Copper Bottle",
// capacity: 950,
//     rating: "4.6",
//     new_price: 749,
//     old_price: 1200,
//     stock: 7,
//     description: "Aqua Batik Geometry: Dive into Wellness with Style  Immerse yourself in the serenity of Aqua Batik Geometry, a captivating design by WellnessCopper. Inspired by the fluidity of batik patterns and the precision of geometric forms, this copper bottle is a testament to the fusion of art and wellness. The soothing aqua hues evoke a sense of calm, making each sip a rejuvenating experience. Embrace the elegance of tradition and the modern aesthetic in every drop. Elevate your hydration with WellnessCopper – where design becomes a celebration of well-being.",
//     "quntity": "",
//     sku: "WS01AI0101",
//     discount: ""
//   },
//   {
//     id: 3,
//     name: "Black Gold Moroccan",
//     image: "Black_Gold_Moroccan_Listing_1.jpg",
//     image_1: "Black_Gold_Moroccan_Listing_2.jpg",
//     image_2: "Black_Gold_Moroccan_Listing_3.jpg",
//     image_3: "Black_Gold_Moroccan_Listing_4.jpg",
//     image_4: "Black_Gold_Moroccan_Listing_5.jpg",
//     category: "Copper Bottle",
// capacity: 950,
//     rating: "4.8",
//     new_price: 749,
//     old_price: 1200,
//     stock: 7,
//     description: "Black Gold Moroccan Elegance: Unveil the Luxe of Wellness  Step into opulence with our Black Gold Moroccan design, a masterpiece from WellnessCopper. Inspired by the intricate beauty of Moroccan artistry, this copper bottle boasts a blend of ebony and gold, creating an aura of timeless sophistication. Every sip from this bottle is a journey into indulgence, combining the potential health benefits of copper with the allure of luxurious design. Elevate your hydration experience and make a statement with the epitome of WellnessCopper elegance.",
//     "quntity": "",
//     sku: "WS01AI0102",
//     discount: ""
//   },
//   {
//     id: 8,
//     name: "Elegant Peacock",
//     image: "ElegantPeacockListing1.png",
//     image_1: "ElegantPeacockListing2.png",
//     image_2: "ElegantPeacockListing3.png",
//     image_3: "ElegantPeacockListing4.png",
//     image_4: "ElegantPeacockListing5.jpg",
//     category: "Copper Bottle",
//   capacity: 950,
//     rating: "4.9",
//     new_price: 749,
//     old_price: 1200,
//     stock: 20,
//     description: "Elegant Peacock Splendor: Wellness Unleashed  Introducing the Elegant Peacock design by WellnessCopper, where every hydration experience is transformed into a display of elegance and vibrancy. This copper bottle, inspired by the regal plumage of the peacock, is a symphony of rich hues and intricate patterns. Each sip is a celebration of health and aesthetics, as the potential benefits of copper meet the elegance of timeless design. Elevate your well-being in style with the allure of the Elegant Peacock.",
//     quantity: "",
//     sku: "WS01AI0107",
//     discount: ""
//   },
//   {
//     id: 14,
//     name: "Modern Art",
//     image: "Modern_Art_Listing_1.jpg",
//     image_1: "Modern_Art_Listing_2.jpg",
//     image_2: "Modern_Art_Listing_3.jpg",
//     image_3: "Modern_Art_Listing_4.jpg",
//     image_4: "Modern_Art_Listing_5.jpg",
//     category: "Copper Bottle",
// capacity: 950,
//     rating: "4.8",
//     new_price: 749,
//     old_price: 1200,
//     stock: 10,
//     description: "Modern Artistry: Wellness Redefined  Indulge in the avant-garde elegance of our Modern Art design at WellnessCopper. This copper bottle transcends the ordinary, transforming hydration into a canvas of contemporary expression. Featuring bold strokes, dynamic lines, and vibrant hues, each sip becomes a masterpiece of well-being. Elevate your style, elevate your health with the cutting-edge design of Modern Art by WellnessCopper. ",
//     "quntity": "",
//     sku: "WS01AI0113",
//     discount: ""
//   },
//   {
//     id: 21,
//     name: "Standard Classic",
//     image: "Standard_Classic_Listing_1.jpg",
//     image_1: "Standard_Classic_Listing_2.jpg",
//     image_2: "Standard_Classic_Listing_3.jpg",
//     image_3: "Standard_Classic_Listing_4.jpg",
//     image_4: "Standard_Classic_Listing_5.jpg",
//     category: "Copper Bottle",
// capacity: 950,
//     rating: "4.9",
//     new_price: 749,
//     old_price: 1200,
//     stock: 50,
//     description: "Pure Copper Matte Elegance: Wellness in Simplicity  Discover the beauty of simplicity with our Pure Copper Matte design at WellnessCopper. This copper bottle, crafted from pure copper with a matte finish, is a celebration of understated elegance. Each sip is an experience in purity, where the potential health benefits of copper merge seamlessly with the sleek sophistication of a matte exterior. Elevate your wellness journey with the timeless charm of Pure Copper Matte. Wellness in every drop, simplicity in every detail. ",
//     "quntity": "",
//     sku: "WS01AI0120",
//     discount: ""
//   },
  // {
  //   id: 22,
  //   name: "Teal Green Geometric",
  //   image: "Teal_Green_Geometric_Listing_1.jpg",
  //   image_1: "Teal_Green_Geometric_Listing_2.jpg",
  //   image_2: "Teal_Green_Geometric_Listing_3.jpg",
  //   image_3: "Teal_Green_Geometric_Listing_4.jpg",
  //   image_4: "Teal_Green_Geometric_Listing_5.jpg",
  //   category: "Copper Bottle",
  //   capacity: 950,
  //   rating: "4.8",
  //   new_price: 749,
  //   old_price: 1200,
  //   stock: 20,
  //   description: "Teal Green Geometry: Wellness Precision  Introducing our Teal Green Geometric Lines design at WellnessCopper, where precision meets elegance. This copper bottle, adorned with intricate teal green lines, is a testament to the harmonious balance of form and function. Each sip becomes a journey into wellness, as the potential health benefits of copper intertwine with the sleek precision of geometric patterns. Elevate your hydration with the modern charm of Teal Green Geometry. Wellness redefined with every drop.",
  //   "quntity": "",
  //   sku: "WS01AI0121",
  //   discount: ""
  // }

  {
    id: 27, 
    name: "Carving Antique Texture Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "5.png",
    image_3: "6.png",
    image_4: "4.png",
    category: "Copper Bottle",
  capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1799,
    stock: 10,
    description: "Embrace a blend of tradition and health with the WELLNESS COPPER Carving Antique Texture Bottle, meticulously crafted from 99% pure copper. This luxurious bottle stands out with its elaborate carving antique texture, making it a stunning piece that goes beyond mere functionality to serve as a work of art.",
    quantity: "",
    sku: "WS01AI0125",
    discount: ""
  },
  {
    id: 28, 
    name: "Hand Hammered Dr Copper Style Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "5.png",
    image_3: "6.png",
    image_4: "4.png",
    category: "Copper Bottle",
  capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1699,
    stock: 10,
    description: "Introducing the WELLNESS COPPER Hand Hammered Dr Copper Style Bottle, expertly crafted from 99% pure copper. This exquisite bottle not only enhances your daily hydration but also adds a touch of artisanal elegance to your routine with its hand-hammered texture, reminiscent of traditional copper craftsmanship.",
    quantity: "",
    sku: "WS01AI0125",
    discount: ""
  },
  {
    id: 29, 
    name: "Diamond Antique Texture Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "5.png",
    image_3: "6.png",
    image_4: "4.png",
    category: "Copper Bottle",
  capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1799,
    stock: 10,
    description: "Elevate your hydration experience with the exquisite WELLNESS COPPER Diamond Antique Texture Bottle, meticulously crafted from 99% pure copper. This bottle is a masterpiece that combines the timeless beauty of an antique diamond texture with the unmatched health benefits of copper.",
    quantity: "",
    sku: "WS01AI0125",
    discount: ""
  },
  {
    id: 30, 
    name: "Hammered Glossy Milton Style Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "5.png",
    image_3: "6.png",
    image_4: "4.png",
    category: "Copper Bottle",
  capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1699,
    stock: 10,
    description: "Introducing the WELLNESS COPPER Hammered Glossy Milton Style Bottle, crafted from 99% pure copper, combining the reliable functionality of a Milton bottle with the enhanced health benefits of copper. This bottle features a hammered texture with a glossy finish, striking the perfect balance between modern elegance and timeless style.",
    quantity: "",
    sku: "WS01AI0125",
    discount: ""
  },
  {
    id: 31, 
    name: "Oxidized Black Carving Antique Texture Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "5.png",
    image_3: "6.png",
    image_4: "4.png",
    category: "Copper Bottle",
  capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1799,
    stock: 10,
    description: "Discover the allure of the WELLNESS COPPER Oxidized Black Carving Antique Texture Bottle, beautifully crafted from 99% pure copper. This exceptional bottle features a distinctive black oxidized finish, paired with an intricate antique carving design that radiates a vintage charm and sophistication.",
    quantity: "",
    sku: "WS01AI0125",
    discount: ""
  },
  {
    id: 32, 
    name: "Raksha Bandhan Celebration Edition Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "5.png",
    image_3: "6.png",
    image_4: "4.png",
    category: "Copper Bottle",
  capacity: 1000,
    rating: "4.1",
    new_price: 749,
    old_price: 1999,
    stock: 10,
    description: "Celebrate the bond of love and protection with the special WELLNESS COPPER Raksha Bandhan Edition Bottle, crafted from 99% pure copper. This limited-edition bottle features a beautifully designed print that captures the essence of Raksha Bandhan, symbolizing the cherished relationship between siblings. It’s not just a bottle—it’s a meaningful gift of health and love.",
    quantity: "",
    sku: "WS01AI0125",
    discount: ""
  },
  {
    id: 33, 
    name: "Stock Market Bull & Bear Edition Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "5.png",
    image_3: "6.png",
    image_4: "4.png",
    category: "Copper Bottle",
  capacity: 1000,
    rating: "4.1",
    new_price: 749,
    old_price: 1999,
    stock: 10,
    description: "Introducing the WELLNESS COPPER Stock Market Bull & Bear Edition Bottle, made from 99% pure copper for health-conscious stock market enthusiasts. Featuring a bold and artistic print of the iconic bull and bear, this bottle is a perfect representation of the market's volatility and power, making it a must-have for traders, investors, and professionals alike.",
    quantity: "",
    sku: "WS01AI0125",
    discount: ""
  },
  {
    id: 8,
    name: "Elegant Peacock",
    image: "ElegantPeacockListing1.png",
    image_1: "ElegantPeacockListing2.png",
    image_2: "ElegantPeacockListing3.png",
    image_3: "ElegantPeacockListing4.png",
    image_4: "ElegantPeacockListing5.jpg",
    category: "Copper Bottle",
  capacity: 950,
    rating: "4.9",
    new_price: 749,
    old_price: 1200,
    stock: 20,
    description: "Elegant Peacock Splendor: Wellness Unleashed  Introducing the Elegant Peacock design by WellnessCopper, where every hydration experience is transformed into a display of elegance and vibrancy. This copper bottle, inspired by the regal plumage of the peacock, is a symphony of rich hues and intricate patterns. Each sip is a celebration of health and aesthetics, as the potential benefits of copper meet the elegance of timeless design. Elevate your well-being in style with the allure of the Elegant Peacock.",
    quantity: "",
    sku: "WS01AI0107",
    discount: ""
  },
  ];

export default new_collections;
