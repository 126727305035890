import React from 'react'
import Popular from '../components/popular/Popular'
// import NewCollections from '../components/newCollections/NewCollections'
import MessageBanner from '../components/messageBanner/MessageBanner'
import SlideBanner from '../components/slideBanner/SlideBanner'

const Home = () => {
  return (
    <>      
      <SlideBanner/>
      {/* <Hero/> */}
      {/* <Featured/> */}
      {/* <About/> */}
      <MessageBanner text={'Together, we can make a difference in the world, one sip at a time.'}/>
      <Popular/>
      {/* <Offers/> */}
      {/* <NewCollections/> */}
      {/* <NewsLetter/> */}
    </>
  )
}

export default Home
