import { useContext, useState } from "react";
import "./CSS/LoginOverlay.css";
import { Link } from "react-router-dom";
import { ShopContext } from "../context/ShopContext";

function SignupOverlay() {
	const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
	const {signup} = useContext(ShopContext);

	const togglePassword = () => {
		setShowPassword(!showPassword);
	};

	const [formData, setFormData] = useState({
		fullName: '',
        email: '',
		phone: '',
        password: '',
		confirmPassword: ''
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    
    const handleSubmit = async(event) => {
        setLoading(!loading)
        event.preventDefault(); // Prevent default form submission
    
		if (formData.password===formData.confirmPassword){
			try {
				const loginState = await signup(formData); // Call the login function from context
				// Handle successful login (e.g., close the overlay)
				console.log("Login State is", loginState);
				if (loginState==="success"){
				  setLoading(false)
				  window.location.href = '/';
				}
			  } catch (error) {
				// Handle login errors
				console.error("Login error:", error);
			  }
		}else{
			console.log("Both password didn't match")
		}
        
      };

	return (
		<>
			<div id="overlay">
        <div id="loginForm">
            <button id="closeLogin">&times;</button> 
            <img src="https://www.wellnesscopper.com/static/media/wc-logo.e1beafa9cafbd214a659.png" alt="Wellness Copper Logo" id="logo"/>
            <h2>Welcome to Wellness Copper</h2>
            <p>Get Extra 10% off on your first order.</p>

            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Full Name" name="fullName" onChange={handleInputChange} required/>
                <input type="email" placeholder="Email" name="email" onChange={handleInputChange} required/>
                <input type="tel" placeholder="Phone Number" name="phone" onChange={handleInputChange} required/>
				<div className="password-container">
					<input type={showPassword ? "text" : "password"} placeholder="Password" name="password" onChange={handleInputChange} required id="password"/>
					<button type="button" className="show-password" onClick={togglePassword}>
						{showPassword ? (
								<span className="ri-eye-line"></span>
							) : (
								<span className="ri-eye-off-line"></span>
							)}
					</button>
				</div>
                <input type="password" placeholder="Confirm Password" name="confirmPassword" onChange={handleInputChange} required/>
                <button type="submit">Signup to get code <span className={loading ? "btn-ring" : ""}></span></button>
				<p className="signup">Already have an account? <Link to='/login'>Login</Link></p>
            </form>
        </div>
    </div>
		</>
	);
}

export default SignupOverlay;
