import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './ProductDisplay.css'
import { ShopContext } from '../../context/ShopContext'

const ProductDisplay = (props) => {
    const history = useNavigate();
    const { product } = props;
    const { addToCart, buyNow } = useContext(ShopContext);

    
    const img = `/assets/copper-bottels/${product.id}/${product.image}`
    const img_1 = `/assets/copper-bottels/${product.id}/${product.image_1}`
    const img_2 = `/assets/copper-bottels/${product.id}/${product.image_2}`
    const img_3 = `/assets/copper-bottels/${product.id}/${product.image_3}`
    const img_4 = `/assets/copper-bottels/${product.id}/${product.image_4}`
    const img_5 = `/assets/copper-bottels/${product.id}/${product.image_5}`
    const img_6 = `/assets/copper-bottels/${product.id}/${product.image_6}`

    const [selectedImage, setSelectedImage] = useState(img);
    
    const handleSubImageClick = (image) => {
        setSelectedImage(image);
    };

    return (
        <div className="container product-display">
        <div className="product-box">
            <div className="image-box">
                <div className="main-image">
                    <img src={selectedImage} alt=""/>
                </div>
                <div className="sub-image">
                    <img className={selectedImage === img ? 'active' : ''} src={img} onClick={() => handleSubImageClick(img)} alt=""/>
                    <img className={selectedImage === img_1 ? 'active' : ''} src={img_1} onClick={() => handleSubImageClick(img_1)} alt=""/>
                    <img className={selectedImage === img_2 ? 'active' : ''} src={img_2} onClick={() => handleSubImageClick(img_2)} alt=""/>
                    <img className={selectedImage === img_3 ? 'active' : ''} src={img_3} onClick={() => handleSubImageClick(img_3)} alt=""/>
                    <img className={selectedImage === img_4 ? 'active' : ''} src={img_4} onClick={() => handleSubImageClick(img_4)} alt=""/>
                    <img className={selectedImage === img_5 ? 'active' : ''} src={img_5} onClick={() => handleSubImageClick(img_5)} alt=""/>
                    <img className={selectedImage === img_6 ? 'active' : ''} src={img_6} onClick={() => handleSubImageClick(img_6)} alt=""/>
                </div>
            </div>
            <div className="description-box">
                <span className="category">{product.category}</span>
                <h2>{product.name}</h2>
                <span>⭐⭐⭐⭐⭐</span>
                <div className="price">
                    <span className="new_price">₹{product.new_price} </span>
                    MRP <span className="old_price">₹{product.old_price}</span>
                    <span className="price-discount"> {((product.old_price - product.new_price)/product.old_price*100).toFixed(1)}% OFF</span>
                    <span className="tax">(Inclusive of all taxes)</span>
                </div>
                {product.stock ?<span className="stock">In Stock</span>:<span className="sold">Sold Out</span>}
                <span className="capacity"><b>Capacity:</b> {product.capacity}ml</span>
                <div className="discount">
                    <h5><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="25">
                            <path
                                d="M10.9042 2.10025L20.8037 3.51446L22.2179 13.414L13.0255 22.6063C12.635 22.9969 12.0019 22.9969 11.6113 22.6063L1.71184 12.7069C1.32131 12.3163 1.32131 11.6832 1.71184 11.2926L10.9042 2.10025ZM13.7327 10.5855C14.5137 11.3666 15.78 11.3666 16.5611 10.5855C17.3421 9.80448 17.3421 8.53815 16.5611 7.7571C15.78 6.97606 14.5137 6.97606 13.7327 7.7571C12.9516 8.53815 12.9516 9.80448 13.7327 10.5855Z">
                            </path>
                        </svg> Available Offers</h5>
                    <div className="offer-list">
                        <div className="offer">
                            <ul>
                                <li><b>FIRSTBUY</b> - Get 5% off on your first order</li>
                                <li><b>WELLNESS100</b> - Buy 2 & get 10% off</li>
                            </ul>
                        </div>
                    </div>
                    <span className="note">* Note - Applied during checkout</span>
                </div>
                <div className={product.stock===0?"button disabled":"button"}>
                    <button onClick={product.stock===0?()=>{} : ()=>{
                        buyNow(product.id)
                        history('/cart')
                        window.scrollTo(0, 0)
                    }}>Buy Now</button>
                    <button onClick={product.stock===0?()=>{} : ()=>{
                        addToCart(product.id)
                    }}>Add To Cart</button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ProductDisplay
