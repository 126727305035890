import React, { useContext, useState } from 'react'
import './Item2.css'
import { ShopContext } from '../../context/ShopContext';
import { Link } from 'react-router-dom';

const Item2 = (props) => {
    const img = `/assets/copper-bottels/${props.id}/${props.image}`;
    const { addToCart } = useContext(ShopContext);
    const [icon, setIcon] = useState(true);
    return (
        // <div className={props.stock === 0 ? 'item-card disabled' : 'item-card'}>
        <div className={'item-card'}>
            <div className="card card-product">
                <div className="card-body">
                    {/* <Link to={props.stock===0? '': `/product/${props.id}`} className="text-inherit text-decoration-none" tabIndex="-1"> */}
                    <Link to={`/product/${props.id}`} className="text-inherit text-decoration-none" tabIndex="-1">
                        <div onClick={window.scrollTo(0, 0)} className="text-center position-relative">
                            <div className="position-absolute top-0 start-0">
                                {/* <span className="badge bg-danger">Sale</span> */}
                            </div>
                            <a href="/" tabIndex="-1"><img src={img} alt="Grocery Ecommerce Template" className="mb-3 img-fluid" /></a>
                            <div className="card-product-action">
                                <a href="/" className="btn-action" data-bs-toggle="modal" data-bs-target="#quickViewModal"
                                    tabIndex="-1">
                                    <i className="ri-eye-line" data-bs-toggle="tooltip" data-bs-html="true" aria-label="Quick View" data-bs-original-title="Quick View"></i>
                                </a>
                            </div>
                        </div>
                    </Link>
                    <div className="text-small mb-1">
                        <a href="/" className="text-decoration-none text-muted" tabIndex="-1"><small>{props.category}</small></a>
                    </div>
                    {/* <h2 className="fs-6"><Link to={props.stock===0? '': `/product/${props.id}`} onClick={window.scrollTo(0, 0)} className="text-inherit text-decoration-none" tabIndex="-1">{props.name}</Link></h2> */}
                    <h2 className="fs-6"><Link to={`/product/${props.id}`} onClick={window.scrollTo(0, 0)} className="text-inherit text-decoration-none" tabIndex="-1">{props.name}</Link></h2>
                    <div>
                        <small className="text-warning">
                            <i className="ri-star-fill"></i>
                            <i className="ri-star-fill"></i>
                            <i className="ri-star-fill"></i>
                            <i className="ri-star-fill"></i>
                            <i className="ri-star-half-line"></i>
                        </small>
                        <span className="text-muted small">&nbsp;{props.rating}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                            <span className="text-dark">₹{props.new_price}&nbsp;</span>
                            <span className="text-decoration-line-through text-muted">₹{props.old_price}</span>
                        </div>
                        <div>
                            <button className={props.stock===0? "btn btn-dark btn-sm" : "btn btn-primary btn-sm"} tabIndex="-1" onClick={props.stock===0? ()=>{}: () => {

                                addToCart(props.id)
                                setIcon(false);

                                setTimeout(() => {
                                    setIcon(true);
                                }, 700);
                            }}>
                                <i className={icon ? "ri-add-line" : "ri-check-line"}></i> &nbsp;
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Item2
