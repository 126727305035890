import React from 'react'
import './CSS/BulkEnquiry.css'

const BulkEnquiry = () => {
  return (
    <div className='bulkenquiry'>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScniIlafp9iDRaxsIekLxIfQKlHqzRBVE5DZD0nPUt6tdsO3A/viewform?embedded=true" title='Bulk Enquiry' width="640" height="1685" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  )
}

export default BulkEnquiry
