import React from 'react'
import './CSS/PrivacyPolicies.css'
import { Link } from 'react-router-dom'

const ReturnRefund = () => {
    return (
        <div className='policies'>
            <div className="container">
                <h1>Return & Refund Policies</h1>
                <section className='text-centre'>
                    <h2>Thank you for shopping at Wellness Copper.</h2>
                    <p>We understand that sometimes your purchase may not meet your expectations. Our Refund and Returns Policy is designed to make the return process as smooth as possible.</p>
                </section>

                <section>
                    <h2>1. Returns Eligibility:</h2>
                    <p>1.1. To be eligible for a return, your item must be unused, in the same condition that you received it, and in its original packaging.</p>
                    <p>1.2. Some products are exempt from being returned due to hygiene and safety reasons. These include [list specific items if applicable].</p>
                </section>

                <section>
                    <h2>2. Initiation of Returns:</h2>
                    <p>2.1. To initiate a return, please contact our Customer Service team at <a href="mailto:info@wellnesscopper.com">info@wellnesscopper.com</a> within 7 days of receiving your order.</p>
                    <p>2.2. Our team will guide you through the return process and provide you with a Return Authorization (RA) number.</p>
                </section>

                <section>
                    <h2>3. Return Shipping:</h2>
                    <p>3.1. Customers are responsible for the cost of return shipping, except in cases where the return is due to a defective or incorrect item.</p>
                    <p>3.2. We recommend using a trackable shipping service and purchasing shipping insurance. We cannot guarantee that we will receive your returned item.</p>
                </section>

                <section>
                    <h2>4. Refunds:</h2>
                    <p>4.1. Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.</p>
                    <p>4.2. If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within 7 days.</p>
                </section>

                <section>
                    <h2>5. Exchanges:</h2>
                    <p>5.1. If you need to exchange an item for the same product, contact our Customer Service team to arrange the exchange. We will provide instructions on how to return the original item.</p>
                </section>

                <section>
                    <h2>6. Damaged or Defective Items:</h2>
                    <p>6.1. If you receive a damaged or defective item, please contact our Customer Service team immediately. We will arrange for the replacement or refund of the item.</p>
                </section>

                <section>
                    <h2>7. Late or Missing Refunds:</h2>
                    <p>7.1. If you haven’t received a refund yet, first check your bank account. Then contact your credit card company, as it may take some time before your refund is officially posted.</p>
                    <p>7.2. If you’ve done all of this and you still have not received your refund, please contact us at <Link to={'mailto:help@wellnesscopper.com'}>help@wellnesscopper.com</Link>.</p>
                </section>

                <section>
                    <h2>8. Changes to this Policy:</h2>
                    <p>8.1. We reserve the right to modify this Refund & Returns Policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website.</p>
                </section>
            </div>

            <section>
                <p>For any further questions regarding our Privacy Policy, please contact us at <Link to={'mailto:help@wellnesscopper.com'}>help@wellnesscopper.com</Link>.</p>
                <p>Thank you for choosing Wellness Copper.</p> <br />
                <p>
                    <b>AVONTIK INTERNATIONAL LLP</b> <br />
                    <b>INDIA</b> <br />
                    <Link to={'mailto:help@wellnesscopper.com'}>help@wellnesscopper.com</Link> <br />
                    <Link to={'tel:+91 8827512215'}>+91 8827512215</Link>
                </p>
            </section>
        </div>
    )
}

export default ReturnRefund
