let all_product = [
  {
    id: 27,
    name: "Carving Antique Texture Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "3.png",
    image_3: "4.png",
    image_4: "0.png",
    image_5: "5.png",
    image_6: "6.png",
    category: "Copper Bottle",
    capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1799,
    stock: 10,
    description:
      "Embrace a blend of tradition and health with the WELLNESS COPPER Carving Antique Texture Bottle, meticulously crafted from 99% pure copper. This luxurious bottle stands out with its elaborate carving antique texture, making it a stunning piece that goes beyond mere functionality to serve as a work of art.",
    quantity: "",
    sku: "WS01AI0125",
    discount: "",
  },
  {
    id: 28,
    name: "Hand Hammered Dr Copper Style Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "3.png",
    image_3: "4.png",
    image_4: "0.png",
    image_5: "5.png",
    image_6: "6.png",
    category: "Copper Bottle",
    capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1699,
    stock: 10,
    description:
      "Introducing the WELLNESS COPPER Hand Hammered Dr Copper Style Bottle, expertly crafted from 99% pure copper. This exquisite bottle not only enhances your daily hydration but also adds a touch of artisanal elegance to your routine with its hand-hammered texture, reminiscent of traditional copper craftsmanship.",
    quantity: "",
    sku: "WS01AI0125",
    discount: "",
  },
  {
    id: 29,
    name: "Diamond Antique Texture Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "3.png",
    image_3: "4.png",
    image_4: "0.png",
    image_5: "5.png",
    image_6: "6.png",
    category: "Copper Bottle",
    capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1799,
    stock: 10,
    description:
      "Elevate your hydration experience with the exquisite WELLNESS COPPER Diamond Antique Texture Bottle, meticulously crafted from 99% pure copper. This bottle is a masterpiece that combines the timeless beauty of an antique diamond texture with the unmatched health benefits of copper.",
    quantity: "",
    sku: "WS01AI0125",
    discount: "",
  },
  {
    id: 30,
    name: "Hammered Glossy Milton Style Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "3.png",
    image_3: "4.png",
    image_4: "0.png",
    image_5: "5.png",
    image_6: "6.png",
    category: "Copper Bottle",
    capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1699,
    stock: 10,
    description:
      "Introducing the WELLNESS COPPER Hammered Glossy Milton Style Bottle, crafted from 99% pure copper, combining the reliable functionality of a Milton bottle with the enhanced health benefits of copper. This bottle features a hammered texture with a glossy finish, striking the perfect balance between modern elegance and timeless style.",
    quantity: "",
    sku: "WS01AI0125",
    discount: "",
  },
  {
    id: 31,
    name: "Oxidized Black Carving Antique Texture Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "3.png",
    image_3: "4.png",
    image_4: "0.png",
    image_5: "5.png",
    image_6: "6.png",
    category: "Copper Bottle",
    capacity: 1000,
    rating: "4.1",
    new_price: 699,
    old_price: 1799,
    stock: 10,
    description:
      "Discover the allure of the WELLNESS COPPER Oxidized Black Carving Antique Texture Bottle, beautifully crafted from 99% pure copper. This exceptional bottle features a distinctive black oxidized finish, paired with an intricate antique carving design that radiates a vintage charm and sophistication.",
    quantity: "",
    sku: "WS01AI0125",
    discount: "",
  },
  {
    id: 32,
    name: "Raksha Bandhan Celebration Edition Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "3.png",
    image_3: "4.png",
    image_4: "0.png",
    image_5: "5.png",
    image_6: "6.png",
    category: "Copper Bottle",
    capacity: 1000,
    rating: "4.1",
    new_price: 749,
    old_price: 1999,
    stock: 10,
    description:
      "Celebrate the bond of love and protection with the special WELLNESS COPPER Raksha Bandhan Edition Bottle, crafted from 99% pure copper. This limited-edition bottle features a beautifully designed print that captures the essence of Raksha Bandhan, symbolizing the cherished relationship between siblings. It’s not just a bottle—it’s a meaningful gift of health and love.",
    quantity: "",
    sku: "WS01AI0125",
    discount: "",
  },
  {
    id: 33,
    name: "Stock Market Bull & Bear Edition Bottle",
    image: "1.png",
    image_1: "2.png",
    image_2: "3.png",
    image_3: "4.png",
    image_4: "0.png",
    image_5: "5.png",
    image_6: "6.png",
    category: "Copper Bottle",
    capacity: 1000,
    rating: "4.1",
    new_price: 749,
    old_price: 1999,
    stock: 10,
    description:
      "Introducing the WELLNESS COPPER Stock Market Bull & Bear Edition Bottle, made from 99% pure copper for health-conscious stock market enthusiasts. Featuring a bold and artistic print of the iconic bull and bear, this bottle is a perfect representation of the market's volatility and power, making it a must-have for traders, investors, and professionals alike.",
    quantity: "",
    sku: "WS01AI0125",
    discount: "",
  },
  {
    id: 8,
    name: "Elegant Peacock",
    image: "ElegantPeacockListing1.png",
    image_1: "ElegantPeacockListing2.png",
    image_2: "ElegantPeacockListing3.png",
    image_3: "ElegantPeacockListing4.png",
    image_4: "ElegantPeacockListing5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.9",
    new_price: 749,
    old_price: 1200,
    stock: 10,
    description:
      "Elegant Peacock Splendor: Wellness Unleashed  Introducing the Elegant Peacock design by WellnessCopper, where every hydration experience is transformed into a display of elegance and vibrancy. This copper bottle, inspired by the regal plumage of the peacock, is a symphony of rich hues and intricate patterns. Each sip is a celebration of health and aesthetics, as the potential benefits of copper meet the elegance of timeless design. Elevate your well-being in style with the allure of the Elegant Peacock.",
    quantity: "",
    sku: "WS01AI0107",
    discount: "",
  },
  {
    id: 1,
    name: "Abstract White",
    image: "Abstract_White_Listing_1.jpg",
    image_1: "Abstract_White_Listing_2.jpg",
    image_2: "Abstract_White_Listing_3.jpg",
    image_3: "Abstract_White_Listing_4.jpg",
    image_4: "Abstract_White_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.7",
    new_price: 749,
    old_price: 1200,
    stock: 10,
    description:
      "Abstract White Elegance by WellnessCopper  Indulge in the artistry of wellness with our Abstract White design – a harmonious blend of modern sophistication and timeless simplicity. Crafted by WellnessCopper, this exquisite copper bottle transcends the ordinary, featuring a captivating abstract pattern in pristine white. Each sip becomes a moment of wellness, marrying the potential health benefits of copper with the elegance of contemporary design. Elevate your hydration experience with a touch of artistic flair. WellnessCopper - where design meets well-being.",
    quantity: "",
    sku: "WS01AI0100",
    discount: "",
  },
  {
    id: 2,
    name: "Aqua Batik Geometirc",
    image: "Aqua_Batik_Geometirc_Listing_1.jpg",
    image_1: "Aqua_Batik_Geometirc_Listing_2.jpg",
    image_2: "Aqua_Batik_Geometirc_Listing_3.jpg",
    image_3: "Aqua_Batik_Geometirc_Listing_4.jpg",
    image_4: "Aqua_Batik_Geometirc_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.6",
    new_price: 749,
    old_price: 1200,
    stock: 7,
    description:
      "Aqua Batik Geometry: Dive into Wellness with Style  Immerse yourself in the serenity of Aqua Batik Geometry, a captivating design by WellnessCopper. Inspired by the fluidity of batik patterns and the precision of geometric forms, this copper bottle is a testament to the fusion of art and wellness. The soothing aqua hues evoke a sense of calm, making each sip a rejuvenating experience. Embrace the elegance of tradition and the modern aesthetic in every drop. Elevate your hydration with WellnessCopper – where design becomes a celebration of well-being.",
    quantity: "",
    sku: "WS01AI0101",
    discount: "",
  },
  {
    id: 3,
    name: "Black Gold Moroccan",
    image: "Black_Gold_Moroccan_Listing_1.jpg",
    image_1: "Black_Gold_Moroccan_Listing_2.jpg",
    image_2: "Black_Gold_Moroccan_Listing_3.jpg",
    image_3: "Black_Gold_Moroccan_Listing_4.jpg",
    image_4: "Black_Gold_Moroccan_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.8",
    new_price: 749,
    old_price: 1200,
    stock: 7,
    description:
      "Black Gold Moroccan Elegance: Unveil the Luxe of Wellness  Step into opulence with our Black Gold Moroccan design, a masterpiece from WellnessCopper. Inspired by the intricate beauty of Moroccan artistry, this copper bottle boasts a blend of ebony and gold, creating an aura of timeless sophistication. Every sip from this bottle is a journey into indulgence, combining the potential health benefits of copper with the allure of luxurious design. Elevate your hydration experience and make a statement with the epitome of WellnessCopper elegance.",
    quantity: "",
    sku: "WS01AI0102",
    discount: "",
  },
  {
    id: 4,
    name: "Blue Checkered",
    image: "Blue_Checkered_Listing_1.jpg",
    image_1: "Blue_Checkered_Listing_2.jpg",
    image_2: "Blue_Checkered_Listing_3.jpg",
    image_3: "Blue_Checkered_Listing_4.jpg",
    image_4: "Blue_Checkered_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.0",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Blue Checkered Bliss: Wellness in Every Drop  Introducing the Blue Checkered design by WellnessCopper – where classic meets contemporary in the art of hydration. Immerse yourself in the charm of this timeless pattern, reminiscent of a tranquil picnic on a sunny day. The soothing blue hues and crisp checks make each sip from this copper bottle a delightful experience. Embrace tradition with a modern twist and let every hydration moment be a celebration of well-being. Elevate your style, elevate your wellness with WellnessCopper. ",
    quantity: "",
    sku: "WS01AI0103",
    discount: "",
  },
  {
    id: 5,
    name: "Blue Gold Moroccan",
    image: "Blue_Gold_Moroccan_Listing_1.jpg",
    image_1: "Blue_Gold_Moroccan_Listing_2.jpg",
    image_2: "Blue_Gold_Moroccan_Listing_3.jpg",
    image_3: "Blue_Gold_Moroccan_Listing_4.jpg",
    image_4: "Blue_Gold_Moroccan_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.3",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Blue Gold Moroccan Majesty: Elevate with Elegance  Unveil the regal allure of our Blue Gold Moroccan design at WellnessCopper. This copper bottle, adorned with the enchanting patterns inspired by Moroccan aesthetics, brings a touch of opulence to your hydration ritual. The deep blue intertwines with gold, creating a masterpiece that merges tradition and modernity. Each sip becomes a moment of luxury, marrying the potential health benefits of copper with the timeless beauty of Moroccan art. Elevate your style, elevate your well-being. WellnessCopper - where every drop is a statement.",
    quantity: "",
    sku: "WS01AI0104",
    discount: "",
  },
  {
    id: 6,
    name: "Blue White Morrocan",
    image: "Blue_White_Morrocan_Listing_1.jpg",
    image_1: "Blue_White_Morrocan_Listing_2.jpg",
    image_2: "Blue_White_Morrocan_Listing_3.jpg",
    image_3: "Blue_White_Morrocan_Listing_4.jpg",
    image_4: "Blue_White_Morrocan_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.0",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Blue White Moroccan Tranquility: Where Tradition Meets Serenity  Introducing the Blue White Moroccan design by WellnessCopper, a mesmerizing fusion of tradition and tranquility. Inspired by the intricate beauty of Moroccan motifs, this copper bottle invites you to embrace serenity with every sip. The calming blue tones dance with crisp white, creating a timeless piece that not only elevates your hydration but also adds a touch of elegance to your day. Experience the marriage of health and aesthetics as you indulge in the artistry of WellnessCopper.",
    quantity: "WS01AI0105",
    sku: "",
    discount: "",
  },
  {
    id: 7,
    name: "Coral  Gold Moroccan",
    image: "Coral_Gold_Moroccan_Listing_1.jpg",
    image_1: "Coral_Gold_Moroccan_Listing_2.jpg",
    image_2: "Coral_Gold_Moroccan_Listing_3.jpg",
    image_3: "Coral_Gold_Moroccan_Listing_4.jpg",
    image_4: "Coral_Gold_Moroccan_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.1",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Coral Gold Moroccan Opulence: Wellness Redefined  Step into the world of opulence with our Coral Gold Moroccan design at WellnessCopper. This copper bottle is a celebration of elegance, marrying the rich hues of coral with the regal touch of gold. Inspired by the intricate patterns of Moroccan art, each sip becomes a journey into luxury and well-being. Immerse yourself in the allure of tradition, where health meets aesthetics. Elevate your hydration, elevate your style with WellnessCopper.",
    quantity: "",
    sku: "WS01AI0106",
    discount: "",
  },
  {
    id: 9,
    name: "Pink Funky Flower",
    image: "Funky_Flower1.jpg",
    image_1: "Funky_Flower2.jpg",
    image_2: "Funky_Flower3.jpg",
    image_3: "Funky_Flower4.jpg",
    image_4: "Funky_Flower5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.5",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Pink Funky Flower Bloom: Wellness in Full Color  Experience the joy of hydration with our Pink Funky Flower design from WellnessCopper. Bursting with vibrant blooms and playful patterns, this copper bottle is not just a vessel; it's a celebration of wellness in full color. The cheerful pink tones and funky florals create a delightful harmony, making each sip an expression of joy and vitality. Elevate your hydration routine with the spirited charm of the Pink Funky Flower. ",
    quantity: "",
    sku: "WS01AI0108",
    discount: "",
  },
  {
    id: 10,
    name: "Light blue Funky Flower",
    image: "Funky_Flower1.jpg",
    image_1: "Funky_Flower2.jpg",
    image_2: "Funky_Flower3.jpg",
    image_3: "Funky_Flower4.jpg",
    image_4: "Funky_Flower5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "3.9",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Light Blue Funky Flower Bliss: A Symphony of Wellness  Discover the artful charm of our Light Blue Funky Flower design at WellnessCopper. This copper bottle is a canvas of wellness and creativity, adorned with lively and intricate floral patterns in serene light blue tones. Each sip becomes a delightful journey, where the potential health benefits of copper harmonize with the playful elegance of floral whimsy. Elevate your hydration experience and embrace the beauty of well-being with the light and lively Funky Flower collection. ",
    quantity: "",
    sku: "WS01AI0109",
    discount: "",
  },
  {
    id: 11,
    name: "Gradient Red",
    image: "Gradient_Red_Listing_1.jpg",
    image_1: "Gradient_Red_Listing_2.jpg",
    image_2: "Gradient_Red_Listing_3.jpg",
    image_3: "Gradient_Red_Listing_4.jpg",
    image_4: "Gradient_Red_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "3.6",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Gradient Red Radiance: Ignite Your Wellness Journey  Introducing the Gradient Red design by WellnessCopper—a spectacular fusion of boldness and elegance. This copper bottle, adorned with a captivating gradient of red hues, is not just a vessel for hydration but a symbol of wellness in motion. As you sip, immerse yourself in the vibrant energy of red, a color associated with passion and vitality. Elevate your well-being in style with the breathtaking beauty of the Gradient Red. Ignite your wellness journey with every drop. ",
    quantity: "",
    sku: "WS01AI0110",
    discount: "",
  },
  {
    id: 12,
    name: "Green Arabic",
    image: "Green_Arabic_Listing_1.jpg",
    image_1: "Green_Arabic_Listing_2.jpg",
    image_2: "Green_Arabic_Listing_3.jpg",
    image_3: "Green_Arabic_Listing_4.jpg",
    image_4: "Green_Arabic_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.4",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Green Arabic Serenity: Elegance in Every Drop  Embark on a journey of tranquility with our Green Arabic design at WellnessCopper. This copper bottle is adorned with exquisite Arabic-inspired patterns in calming green tones, creating an oasis of serenity in every sip. Let the elegance of the design harmonize with the potential health benefits of copper, making your hydration ritual a moment of well-being and sophistication. Elevate your style, elevate your wellness with the Green Arabic collection. ",
    quantity: "",
    sku: "WS01AI0111",
    discount: "",
  },
  {
    id: 13,
    name: "Grey Yellow ",
    image: "Grey_Yellow_Ikkat_Listing_1.jpg",
    image_1: "Grey_Yellow_Ikkat_Listing_2.jpg",
    image_2: "Grey_Yellow_Ikkat_Listing_3.jpg",
    image_3: "Grey_Yellow_Ikkat_Listing_4.jpg",
    image_4: "Grey_Yellow_Ikkat_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.2",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Grey Yellow Harmony: Elevate with Subtle Radiance  Discover the perfect fusion of subtlety and radiance with our Grey Yellow design at WellnessCopper. This copper bottle, adorned in a sophisticated grey with pops of vibrant yellow, brings a touch of modern elegance to your hydration routine. The gentle interplay of colors not only reflects your refined style but also complements the potential health benefits of copper. Elevate your well-being with every sip in the harmonious embrace of Grey Yellow.",
    quantity: "",
    sku: "WS01AI0112",
    discount: "",
  },
  // {
  //   id: 14,
  //   name: "Modern Art",
  //   image: "Modern_Art_Listing_1.jpg",
  //   image_1: "Modern_Art_Listing_2.jpg",
  //   image_2: "Modern_Art_Listing_3.jpg",
  //   image_3: "Modern_Art_Listing_4.jpg",
  //   image_4: "Modern_Art_Listing_5.jpg",
  //   category: "Copper Bottle",
  // capacity: 950,
  //   rating: "4.8",
  //   new_price: 749,
  //   old_price: 1200,
  //   stock: 0,
  //   description: "Modern Artistry: Wellness Redefined  Indulge in the avant-garde elegance of our Modern Art design at WellnessCopper. This copper bottle transcends the ordinary, transforming hydration into a canvas of contemporary expression. Featuring bold strokes, dynamic lines, and vibrant hues, each sip becomes a masterpiece of well-being. Elevate your style, elevate your health with the cutting-edge design of Modern Art by WellnessCopper. ",
  //   quantity: "",
  //   sku: "WS01AI0113",
  //   discount: ""
  // },
  {
    id: 15,
    name: "Navy Blue Moroccan",
    image: "Navy_Blue_Moroccan1.jpg",
    image_1: "Navy_Blue_Moroccan2.jpg",
    image_2: "Navy_Blue_Moroccan3.jpg",
    image_3: "Navy_Blue_Moroccan4.jpg",
    image_4: "Navy_Blue_Moroccan5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.5",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Navy Blue Moroccan Nightfall: Timeless Elegance  Journey into timeless elegance with our Navy Blue Moroccan design at WellnessCopper. This copper bottle is a captivating tapestry of deep navy and intricate Moroccan motifs, evoking the mystery of a starlit night. Each sip is a luxurious experience, where the potential health benefits of copper seamlessly blend with the allure of classic design. Elevate your wellness journey under the midnight inspiration of Navy Blue Moroccan. ",
    quantity: "",
    sku: "WS01AI0114",
    discount: "",
  },
  {
    id: 16,
    name: "Pink Marble",
    image: "Pink_Marble_Listing_1.jpg",
    image_1: "Pink_Marble_Listing_2.jpg",
    image_2: "Pink_Marble_Listing_3.jpg",
    image_3: "Pink_Marble_Listing_4.jpg",
    image_4: "Pink_Marble_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.2",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      " Pink Marble Elegance: Embrace Radiance  Dive into the ethereal beauty of our Pink Marble design at WellnessCopper. This copper bottle, adorned with swirling pink marbled patterns, is a celebration of elegance and vitality. Each sip becomes a moment of indulgence, where the potential health benefits of copper intertwine with the graceful allure of marble. Elevate your well-being with the luminous charm of Pink Marble. Make hydration an art, make it WellnessCopper.",
    quantity: "",
    sku: "WS01AI0115",
    discount: "",
  },
  {
    id: 17,
    name: "Purple Gold Marble",
    image: "PurpleGoldMarbleListing1.jpg",
    image_1: "PurpleGoldMarbleListing2.jpg",
    image_2: "PurpleGoldMarbleListing3.jpg",
    image_3: "PurpleGoldMarbleListing4.jpg",
    image_4: "PurpleGoldMarbleListing5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.3",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Purple Gold Marble Opulence: Wellness in Royalty  Unveil the regal beauty of our Purple Gold Marble design at WellnessCopper. This copper bottle, adorned with majestic swirls of purple and gold, transforms hydration into a royal affair. With each sip, immerse yourself in the luxurious dance of potential health benefits and the opulent allure of marble. Elevate your wellness journey with the richness of Purple Gold Marble - where every drop is a sip fit for royalty. ",
    quantity: "",
    sku: "WS01AI0116",
    discount: "",
  },
  {
    id: 18,
    name: "Red Block Flower",
    image: "Red_Block_Flower_Listing_1.jpg",
    image_1: "Red_Block_Flower_Listing_2.jpg",
    image_2: "Red_Block_Flower_Listing_3.jpg",
    image_3: "Red_Block_Flower_Listing_4.jpg",
    image_4: "Red_Block_Flower_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.1",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Red Block Flower Radiance: Blooms of Wellness  Experience the vibrant allure of our Red Block Flower design at WellnessCopper. This copper bottle is a canvas of radiant red, adorned with bold block flowers that symbolize strength and vitality. Each sip becomes a celebration of well-being, where the potential health benefits of copper intertwine with the bold elegance of floral patterns. Elevate your hydration with the empowering beauty of Red Block Flower. Embrace the radiance of wellness with every drop.",
    quantity: "",
    sku: "WS01AI0117",
    discount: "",
  },
  {
    id: 19,
    name: "Sky Blue Marble",
    image: "SkyBlueMarbleListing1.jpg",
    image_1: "SkyBlueMarbleListing2.jpg",
    image_2: "SkyBlueMarbleListing3.jpg",
    image_3: "SkyBlueMarbleListing4.jpg",
    image_4: "SkyBlueMarbleListing5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.3",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      " Sky Blue Marble Serenity: Wellness in Every Ripple  Introducing the Sky Blue Marble design by WellnessCopper – where the serene hues of the sky meet the timeless elegance of marble. This copper bottle, adorned with gentle marbled patterns in tranquil blue, transforms hydration into a calming ritual. With each sip, immerse yourself in the potential health benefits of copper, harmonizing with the peaceful allure of Sky Blue Marble. Elevate your wellness journey and hydrate in the embrace of tranquility.",
    quantity: "",
    sku: "WS01AI0118",
    discount: "",
  },
  {
    id: 20,
    name: "Smooth Grey",
    image: "Smooth_Grey_Listing_1.jpg",
    image_1: "Smooth_Grey_Listing_2.jpg",
    image_2: "Smooth_Grey_Listing_3.jpg",
    image_3: "Smooth_Grey_Listing_4.jpg",
    image_4: "Smooth_Grey_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.1",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Smooth Grey Texture: Wellness in Simplicity  Embrace the simplicity of sophistication with our Smooth Grey Texture design at WellnessCopper. This copper bottle, adorned in a seamless grey texture, is a testament to the beauty found in subtlety. Each sip becomes a moment of calm, where the potential health benefits of copper blend effortlessly with the understated elegance of smooth grey. Elevate your wellness journey with the soothing charm of Smooth Grey Texture. Experience wellness in its purest form.",
    quantity: "",
    sku: "WS01AI0119",
    discount: "",
  },
  {
    id: 21,
    name: "Standard Classic",
    image: "Standard_Classic_Listing_1.jpg",
    image_1: "Standard_Classic_Listing_2.jpg",
    image_2: "Standard_Classic_Listing_3.jpg",
    image_3: "Standard_Classic_Listing_4.jpg",
    image_4: "Standard_Classic_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.9",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Pure Copper Matte Elegance: Wellness in Simplicity  Discover the beauty of simplicity with our Pure Copper Matte design at WellnessCopper. This copper bottle, crafted from pure copper with a matte finish, is a celebration of understated elegance. Each sip is an experience in purity, where the potential health benefits of copper merge seamlessly with the sleek sophistication of a matte exterior. Elevate your wellness journey with the timeless charm of Pure Copper Matte. Wellness in every drop, simplicity in every detail. ",
    quantity: "",
    sku: "WS01AI0120",
    discount: "",
  },
  // {
  //   id: 22,
  //   name: "Teal Green Geometric",
  //   image: "Teal_Green_Geometric_Listing_1.jpg",
  //   image_1: "Teal_Green_Geometric_Listing_2.jpg",
  //   image_2: "Teal_Green_Geometric_Listing_3.jpg",
  //   image_3: "Teal_Green_Geometric_Listing_4.jpg",
  //   image_4: "Teal_Green_Geometric_Listing_5.jpg",
  //   category: "Copper Bottle",
  // capacity: 950,
  //   rating: "4.8",
  //   new_price: 749,
  //   old_price: 1200,
  //   stock: 0,
  //   description: "Teal Green Geometry: Wellness Precision  Introducing our Teal Green Geometric Lines design at WellnessCopper, where precision meets elegance. This copper bottle, adorned with intricate teal green lines, is a testament to the harmonious balance of form and function. Each sip becomes a journey into wellness, as the potential health benefits of copper intertwine with the sleek precision of geometric patterns. Elevate your hydration with the modern charm of Teal Green Geometry. Wellness redefined with every drop.",
  //   quantity: "",
  //   sku: "WS01AI0121",
  //   discount: ""
  // },
  {
    id: 23,
    name: "Yellow zigzag ",
    image: "Yellow_Zig-Zag_Listing_1.jpg",
    image_1: "Yellow_Zig-Zag_Listing_2.jpg",
    image_2: "Yellow_Zig-Zag_Listing_3.jpg",
    image_3: "Yellow_Zig-Zag_Listing_4.jpg",
    image_4: "Yellow_Zig-Zag_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.0",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Yellow Zigzag Radiance: Wellness in Motion  Step into the vibrancy of our Yellow Zigzag Lines design at WellnessCopper. This copper bottle, adorned with energetic yellow zigzag lines, brings a sense of dynamic motion to your hydration experience. Each sip becomes a burst of wellness, where the potential health benefits of copper dance with the lively charm of zigzag patterns. Elevate your well-being with the spirited radiance of Yellow Zigzag. Make every drop a celebration of movement and vitality.",
    quantity: "",
    sku: "WS01AI0122",
    discount: "",
  },
  {
    id: 24,
    name: "White Flower",
    image: "White_Flower_Listing_1.jpg",
    image_1: "White_Flower_Listing_2.jpg",
    image_2: "White_Flower_Listing_3.jpg",
    image_3: "White_Flower_Listing_4.jpg",
    image_4: "White_Flower_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "3.5",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "White Flower Elegance: Bloom into Wellness  Experience the grace of nature with our White Flower design at WellnessCopper. This copper bottle, adorned with delicate white flowers, is a celebration of purity and elegance. Each sip becomes a journey into wellness, as the potential health benefits of copper intertwine with the serene beauty of blooming florals. Elevate your hydration with the timeless charm of White Flower Elegance. Embrace wellness, one sip at a time. ",
    quantity: "",
    sku: "WS01AI0123",
    discount: "",
  },
  {
    id: 25,
    name: "White Gold Marble",
    image: "WhiteGoldMarbleListing1.jpg",
    image_1: "WhiteGoldMarbleListing2.jpg",
    image_2: "WhiteGoldMarbleListing3.jpg",
    image_3: "WhiteGoldMarbleListing4.jpg",
    image_4: "WhiteGoldMarbleListing5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.2",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      " White Gold Marble Luxe: Wellness in Opulence  Indulge in the opulent allure of our White Gold Marble design at WellnessCopper. This copper bottle, graced with the lavish swirls of white and gold, transforms hydration into a luxurious experience. Each sip becomes a moment of grandeur, where the potential health benefits of copper meld seamlessly with the timeless elegance of marble. Elevate your wellness journey with the lavish charm of White Gold Marble Luxe. Hydration redefined in a palette of sophistication. ",
    quantity: "",
    sku: "WS01AI0124",
    discount: "",
  },
  {
    id: 26,
    name: "Yellow Black Geometric",
    image: "Yellow_Black_Geometric_Listing_1.jpg",
    image_1: "Yellow_Black_Geometric_Listing_2.jpg",
    image_2: "Yellow_Black_Geometric_Listing_3.jpg",
    image_3: "Yellow_Black_Geometric_Listing_4.jpg",
    image_4: "Yellow_Black_Geometric_Listing_5.jpg",
    category: "Copper Bottle",
    capacity: 950,
    rating: "4.1",
    new_price: 749,
    old_price: 1200,
    stock: 0,
    description:
      "Yellow Black Geometric Precision: Wellness in Symmetry  Introducing our Yellow Black Geometric design at WellnessCopper, where precision meets vitality. This copper bottle, adorned with bold yellow and black geometric patterns, is a testament to the harmony of structure and energy. Each sip becomes a journey into wellness, as the potential health benefits of copper merge with the sleek elegance of geometric precision. Elevate your hydration with the modern charm of Yellow Black Geometry. Wellness redefined with every drop, a symphony of balance and vibrancy.",
    quantity: "",
    sku: "WS01AI0125",
    discount: "",
  },
];

export default all_product;
