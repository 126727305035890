// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msg-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    margin: 40px auto;
    margin-top: 0;
    background-image: linear-gradient(90deg, rgba(0,0,0,0.30), rgba(0,0,0,0.5)), url(https://images.pexels.com/photos/1310016/pexels-photo-1310016.jpeg);
    background-attachment: fixed;
    background-size: cover;
    padding: 40px;
}

.msg-banner h4{
    font-size: 48px;
    font-weight: 500;
    width: 80%;
    color: #fff;
}



/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px){

}

/*  */
@media only screen and (max-width: 992px){
    
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px){
    
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px){
    

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px){
    .msg-banner h4{
        font-size: 32px;
        width: 100%;
        font-weight: 500;
        color: #fff;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/messageBanner/MessageBanner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,oJAAoJ;IACpJ,4BAA4B;IAC5B,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,UAAU;IACV,WAAW;AACf;;;;AAIA,oEAAoE;AACpE;;AAEA;;AAEA,KAAK;AACL;;AAEA;;;AAGA,qDAAqD;AACrD;;AAEA;;;AAGA,oEAAoE;AACpE;;;AAGA;;AAEA,iDAAiD;AACjD;IACI;QACI,eAAe;QACf,WAAW;QACX,gBAAgB;QAChB,WAAW;IACf;AACJ","sourcesContent":[".msg-banner{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-height: 300px;\n    margin: 40px auto;\n    margin-top: 0;\n    background-image: linear-gradient(90deg, rgba(0,0,0,0.30), rgba(0,0,0,0.5)), url(https://images.pexels.com/photos/1310016/pexels-photo-1310016.jpeg);\n    background-attachment: fixed;\n    background-size: cover;\n    padding: 40px;\n}\n\n.msg-banner h4{\n    font-size: 48px;\n    font-weight: 500;\n    width: 80%;\n    color: #fff;\n}\n\n\n\n/* Extra large devices (large laptops and desktops, 1200px and up) */\n@media only screen and (min-width: 1200px){\n\n}\n\n/*  */\n@media only screen and (max-width: 992px){\n    \n}\n\n\n/* Medium devices (landscape tablets, 768px and up) */\n@media only screen and (max-width: 768px){\n    \n}\n\n\n/* Small devices (portrait tablets and large phones, 600px and up) */\n@media only screen and (max-width: 600px){\n    \n\n}\n\n/* Extra small devices (phones, 600px and down) */\n@media only screen and (max-width: 600px){\n    .msg-banner h4{\n        font-size: 32px;\n        width: 100%;\n        font-weight: 500;\n        color: #fff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
