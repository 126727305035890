import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/ContactUs.css'
import { useState } from 'react'
import loadIcon from '../components/assets/loading.gif'

const ContactUs = () => {
    const [loading, setLoading] = useState(false);
    const [popup, setPopup] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        window.scrollTo(0, 0)
        document.body.style.overflow = 'hidden';

        // Dummy API endpoint for demonstration purposes
        const apiUrl = process.env.REACT_APP_BACKEND_API_ENDPOINT + 'contact_form/'; // Replace with your actual API endpoint

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    first_name: e.target.elements['first-name'].value,
                    last_name: e.target.elements['last-name'].value,
                    email: e.target.elements['email'].value,
                    phone: e.target.elements['phone'].value,
                    msg: e.target.elements['msg'].value,
                }),
            });

            const data = await response.json();

            console.log(data)
            // Show confirmation popup after response is received
            setPopup(true);
            // window.alert('Your message has been sent. Thank you!');
            document.body.style.overflow = '';
            e.target.elements['first-name'].value = ''
            e.target.elements['last-name'].value = ''
            e.target.elements['email'].value = ''
            e.target.elements['phone'].value = ''
            e.target.elements['msg'].value = ''

        } catch (error) {
            console.error('Error:', error);
            document.body.style.overflow = '';
            e.target.elements['first-name'].value = ''
            e.target.elements['last-name'].value = ''
            e.target.elements['email'].value = ''
            e.target.elements['phone'].value = ''
            e.target.elements['msg'].value = ''
        }
        
        setLoading(false);
        document.body.style.overflow = '';
    };
    return (
        <>
            <div className={loading ? 'loading visible' : 'loading'}>
                {/* <div className='loading'> */}
                <div className="loading-box">
                    <img src={loadIcon} alt="" width={100} />
                </div>
            </div>
            <div className={popup ? 'loading visible' : 'loading'}>
                <div className={popup ? 'popup visible' : 'popup'}>
                    <h3>Thank You!</h3>
                    <p>Your response is successfully submitted</p>
                    <button onClick={()=>{setPopup(false)}}>Close</button>
                </div>
            </div>
            
            <div className="about-page header">
                <h2>Contact Us</h2>
            </div>
            <div className="container contact-container">
                <div className="contact-us">
                    <div className="contact-form">
                        <form onSubmit={handleSubmit}>
                            <div className="name">
                                <div className="first-name">
                                    <label htmlFor="first-name">First Name</label>
                                    <input type="text" name="first-name" id="" />
                                </div>
                                <div className="last-name">
                                    <label htmlFor="last-name">Last Name</label>
                                    <input type="text" name="last-name" id="" />
                                </div>
                            </div>
                            <div className="contact">
                                <div className="email">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" name="email" id="" />
                                </div>
                                <div className="phone">
                                    <label htmlFor="phone">Phone</label>
                                    <input type="tel" name="phone" id="" />

                                </div>
                            </div>
                            <div className="message">
                                <label htmlFor="msg">Message</label>
                                <textarea name="msg" id="" cols="30" rows="6"></textarea>
                            </div>
                            <div><button>Submit</button></div>
                        </form>
                    </div>
                    <div className="contact-details">
                        <h3>Contact Info</h3>
                        <div>
                            <ul>
                                <li><Link to='mailto:contact@wellnesscopper.com'><i class="ri-mail-fill"></i> contact@wellnesscopper.com</Link></li>
                                <li><Link to='mailto:help@wellnesscopper.com'><i class="ri-mail-fill"></i> help@wellnesscopper.com</Link></li>
                                <li><Link to='tel:+91 8827512215'><i class="ri-phone-fill"></i> +91 8827512215</Link></li>
                                <li><Link to='tel:+91 7581969594'><i class="ri-phone-fill"></i> +91 7581969594</Link></li>
                                <li><Link to=''><i class="ri-map-pin-2-fill"></i> 22 Moti Nagar, Pipli Naka, Ujjain M.P 456001, India</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs;
