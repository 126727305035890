import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import logo from "../assets/wc-logo.png"
import upi from '../assets/payments/upi.svg'
import rupay from '../assets/payments/rupay.svg'
import visa from '../assets/payments/visa.svg'
import master from '../assets/payments/mastercard.svg'
import amazon from '../assets/delivery/as.svg'
import delivery from '../assets/delivery/dl.svg'
import bluedart from '../assets/delivery/bd.png'
import ekart from '../assets/delivery/ek.jpg'

const Footer = () => {
    return (
        <>
            <footer>
                <div className="container content">
                    <div className="sec aboutus">
                        <h2>About Us</h2>
                        <img src={logo} alt="" width={80}/>
                        <p>We Wellness Copper bringing to you the wellness of pure copper</p>
                        <div className="follow-us">
                            <h4>Follow us:</h4>
                            <ul className="sci">
                                <li><Link to={'https://www.facebook.com/profile.php?id=61558196227494'}><i className="ri-facebook-fill"></i></Link></li>
                                {/* <li><Link to={'https://www.facebook.com/profile.php?id=61554272365181'}><i className="ri-twitter-fill"></i></Link></li> */}
                                <li><Link to={'https://www.instagram.com/copperbottles.wellness'}><i className="ri-instagram-line"></i></Link></li>
                                {/* <li><Link to={'https://www.facebook.com/profile.php?id=61554272365181'}><i className="ri-youtube-fill"></i></Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="sec quicklinks">
                        <h2>Information</h2>
                        <ul>
                            <li><Link to={'/terms-of-service'} onClick={()=>{window.scrollTo(0,0)}}>Terms of Service</Link></li>
                            <li><Link to={'/privacy-policy'} onClick={()=>{window.scrollTo(0,0)}}>Privacy Policy</Link></li>
                            <li><Link to={'/refund-policy'} onClick={()=>{window.scrollTo(0,0)}}>Return & Refund Policy</Link></li>
                            <li><Link to={'/shipping-policy'} onClick={()=>{window.scrollTo(0,0)}}>Shipping Policy</Link></li>
                            
                        </ul>
                    </div>
                    <div className="sec quicklinks">
                        <h2>Pages</h2>
                        <ul>
                            <li><Link to="/" onClick={()=>{window.scrollTo(0,0)}}>Home</Link></li>
                            <li><Link to="/shop" onClick={()=>{window.scrollTo(0,0)}}>All Products</Link></li>
                            <li><Link to='about'>About Us</Link></li>
                            <li><Link to='contact'>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="sec contact">
                        <h2>Contact Us</h2>
                        <ul className="info">
                            <li>
                                <span><i className="ri-phone-fill"></i></span><p><Link to='tel:+91 8827512215'>+91 8827512215</Link></p>
                            </li>
                            <li>
                                <span><i className="ri-mail-fill"></i></span>
                                <p><Link to={'mailto:info@wellnesscopper.com'}>info@wellnesscopper.com</Link></p>
                            </li>
                            <li>
                                <span><i className="ri-map-pin-fill"></i></span>
                                <p>22 Moti Nagar, Ujjain 456001 (M.P) INDIA</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="container partners mt-4">
                    <div className="payment-partner">
                        <h3>Payment Partner</h3>
                        <div className="images">
                            <img src={upi} alt="" width={60} />
                            <img src={rupay} alt="" width={60} />
                            <img src={visa} alt="" width={60} />
                            <img src={master} alt="" width={60} />
                        </div>
                    </div>
                    <div className="payment-partner">
                        <h3>Delivery Partner</h3>
                        <div className="images">
                            <img src={amazon} alt="" width={80} />
                            <img src={ekart} alt="" width={80} />
                            <img src={delivery} alt="" width={80} />
                            <img src={bluedart} alt="" width={80} />
                        </div>
                    </div>
                </div>
                <div className="copyrightText">
                    <p>Copyright @ 2024 Wellness Copper owned by <Link to='https://www.avontikinternational.com'>Avontik International LLP</Link>. All Rights Reserves.</p>
                </div>
            </footer>
        </>
    )
}

export default Footer
