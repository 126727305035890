import React, { createContext, useState, useEffect } from "react";
import all_product from '../components/assets/all_product';
import Cookies from 'js-cookie';

export const ShopContext = createContext(null);

const getDefaultCart = ()=>{
    let cart = {};
    for (let index = 0; index < all_product.length; index++) {
        cart[index] = 0;
    }
    return cart;
}

const ShopContextProvider = (props)=>{
    const [cartItems, setCartItems] = useState(() => {
      // Retrieve cart from cookies on initial render
      const storedCart = Cookies.get('cart');
      return storedCart ? JSON.parse(storedCart) : getDefaultCart();
    });
    const [discount, setDiscount] = useState([0, '']);
    const [accessToken, SetAccessToken] = useState(Cookies.get('accessToken'));
    const [isLoggedIn, setIsLoggedIn] = useState((accessToken!==undefined));
    const [couponApplied, setCouponApplied] = useState([false, '']);

    const signup = async(data) => {
      // You might store login data in localStorage or cookies here
      try {
          const response = await fetch('http://localhost:4000/user/registerUser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });
      
          if (response.ok) {
            const data = await response.json();
            
            setIsLoggedIn(true);
            SetAccessToken(data.token); // Assuming API returns accessToken
            // Store login data in localStorage or cookies if needed
            // Set access token cookie (adjust expiration as needed)
            Cookies.set('accessToken', data.token, { expires: 1 / 24 }); // Expires in 1 hour
            
            return await "success"
          } else {
            // Handle login error (e.g., display error message)
            console.error('Login failed:', response.status); 
            return "error"
          }
        } catch (error) {
          console.error('Error during login:', error);
          return "error"
        }
    };
    
    const login = async(data) => {
        // You might store login data in localStorage or cookies here
        try {
            const response = await fetch('http://localhost:4000/user/loginUser', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            });
        
            if (response.ok) {
              const data = await response.json();
              
              setIsLoggedIn(true);
              SetAccessToken(data.token); // Assuming API returns accessToken
              // Store login data in localStorage or cookies if needed
              // Set access token cookie (adjust expiration as needed)
              Cookies.set('accessToken', data.token, { expires: 1 / 24 }); // Expires in 1 hour
              
              return await "success"
            } else {
              // Handle login error (e.g., display error message)
              console.error('Login failed:', response.status); 
              return "error"
            }
          } catch (error) {
            console.error('Error during login:', error);
            return "error"
          }
      };
    
      const logout = () => {
        setIsLoggedIn(false);
        SetAccessToken(null);
        Cookies.set('accessToken', undefined)
        // Clear login data from localStorage or cookies 
      };
    
    useEffect(() => {
      Cookies.set('cart', JSON.stringify(cartItems));
    }, [cartItems]);

    const buyNow = (itemId) =>{
        setCartItems({ [itemId]: 1 });
    }
    
    const addToCart = (itemId) =>{
        setCartItems((prev)=>({...prev, [itemId]:prev[itemId]+1}));

        if (couponApplied[0]){
          checkPromo(couponApplied[1], 1);
        }
    }
    
    const removeFromCart = (itemId) =>{
        setCartItems((prev)=>({...prev, [itemId]:prev[itemId]-1}))
        if (couponApplied[0]){
          checkPromo(couponApplied[1], 1);
        }
    }
    const removeAllFromCart = (itemId) =>{
        setCartItems((prev)=>({...prev, [itemId]:prev[itemId]*0}))

        if (couponApplied[0]){
          checkPromo(couponApplied[1], 1);
        }
    }

    const getTotalCartAmount = () =>{
        let totalAmount = 0;
        for(const item in cartItems){
            if(cartItems[item]>0){
                let itemInfo = all_product.find((product)=>product.id===Number(item));
                totalAmount += itemInfo.new_price * cartItems[item];
            }
        }
        return totalAmount;
    }

    const getTotalCartItems = () =>{
        let totalItem = 0;
        for(const item in cartItems){
            if(cartItems[item]>0){
                totalItem += cartItems[item];
            }
        }
        return Math.floor(totalItem);
    }

    const getTotalAmount = () =>{
        let totalAmt = 0;
        const totalCartAmount = getTotalCartAmount();
        if(discount[0] === -1){
            return totalCartAmount;
        }
        totalAmt = totalCartAmount - (totalCartAmount * discount[0] / 100);
        return Math.floor(totalAmt.toFixed(2));

    }

    const getDiscount = () =>{
        return discount;
    }

    const promoCodes = {
        'FIRSTBUY': { discount: 5, type: 'first_order' }, 
        'WELLNESS100': { discount: 10, type: 'quantity', minQty: 2 },
        'GITIKA': { discount: 99, type: 'quantity', minQty: 1 },
        'SUMMER20': { discount: 20, type: 'min_amount', minAmount: 500 } 
        // ... more coupons
      };

    const checkPromo = (value, diff=0) => {
        if (promoCodes.hasOwnProperty(value)) {
          const coupon = promoCodes[value];
      
          // Validation based on the coupon type
          switch (coupon.type) {
            case 'first_order':
              // Implement logic to check if it's the user's first order 
              // ... (you'll likely need additional data for this)
              if(true){
                setDiscount([coupon.discount, '']);
                setCouponApplied([true, value]);
                return [coupon.discount, ''];
              }else{
                setDiscount([-1, 'Invalid Coupon code']);
                setCouponApplied([false, '']);
                return [-1, 'Invalid Coupon code'];
              }
                
            //   break;
            case 'quantity':
              if (getTotalCartItems()-diff >= coupon.minQty) {
                setDiscount([coupon.discount, '']);
                setCouponApplied([true, value]);
                return [coupon.discount, ''];
              }else{
                setDiscount([-1, 'Min. 2 items are needed for this']);
                setCouponApplied([false, '']);
                return [-1, 'Min. 2 items are needed for this'];
              }
            //   break;
            case 'min_amount':
              if (getTotalCartAmount() >= coupon.minAmount) {
                setDiscount([coupon.discount, '']);
                setCouponApplied([true, value]);
                return [coupon.discount, ''];
              }else{
                setDiscount([-1, 'Min. cart amount should be '+ coupon.minAmount]);
                setCouponApplied([false, '']);
                return [-1, 'Min. cart amount should be '+ coupon.minAmount];
              }
            //   break;
            default:
              break; 
          }
      
          // No matching condition found
          setDiscount([-1, 'Invalid Coupon code']);
          setCouponApplied([false, '']);
          return [-1, 'Invalid Coupon code'];
      
        } else {
          // No matching promo code found
          setDiscount([-1, 'Invalid Coupon code']);
          setCouponApplied([false, '']);
          return [-1, 'Invalid Coupon code'];
        }
      };
      
    
    const contextValue = {all_product, cartItems, addToCart, removeFromCart, removeAllFromCart, getTotalCartAmount, getTotalCartItems, buyNow, checkPromo, getTotalAmount, getDiscount, isLoggedIn, accessToken, login, signup, logout}

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    )
}

export default ShopContextProvider;
