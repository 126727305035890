import React from 'react'
import './MessageBanner.css'

const MessageBanner = (props) => {
  return (
    <div className='msg-banner'>
        <h4>
        {props.text}
        </h4>
      
    </div>
  )
}

export default MessageBanner
