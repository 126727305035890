// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policies{
    line-height: 1.6;
}

.policies h1 {
    margin: 30px 0;
    text-align: center;
}

.policies .container {
    width: 80%;
    margin: auto;
    padding: 20px;
    text-align: left;
}

.policies section {
    margin-bottom: 20px;
}

.policies section h2 {
    color: #333;
}

section.text-centre{
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CSS/PrivacyPolicies.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".policies{\n    line-height: 1.6;\n}\n\n.policies h1 {\n    margin: 30px 0;\n    text-align: center;\n}\n\n.policies .container {\n    width: 80%;\n    margin: auto;\n    padding: 20px;\n    text-align: left;\n}\n\n.policies section {\n    margin-bottom: 20px;\n}\n\n.policies section h2 {\n    color: #333;\n}\n\nsection.text-centre{\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
