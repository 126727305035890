import React from 'react'
// import CartItems from '../components/cartItems/CartItems'
import ItemCart from '../components/ItemCart/ItemCart'

const Cart = () => {
  return (
    <div>
      <ItemCart/>
      {/* <CartItems/> */}
    </div>
  )
}

export default Cart
