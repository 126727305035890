import React from 'react'
import './DescriptionBox.css'

const DescriptionBox = () => {
  return (
    <>
      {/* <div className='descriptionbox container'>
      <div className="descriptionbox-navigator">
        <div className="descriptionbox-nav-box">Description</div>
        <div className="descriptionbox-nav-box fade">Specifications</div>
      </div>
      <div className="descriptionbox-description">
        <p>Welcome to WellnessCopper, where we blend the richness of ancient wellness traditions with the elegance of modern living. As specialists in copper bottle offerings, we provide not just a vessel for hydration but a conduit to potential health benefits. Crafted with precision and care, our bottles are a harmonious fusion of artistry and functionality. Embrace the warmth of copper, promote well-being, and join a community that values tradition, sustainability, and a mindful lifestyle. Elevate your hydration experience with WellnessCopper – a journey of health, beauty, and timeless elegance.</p>
      </div>
    </div> */}

      <div class="site-wrapper">
        <section class="tabs-wrapper">
          <div class="tabs-container">
            <div class="tabs-block">
              <div class="tabs">
                <input type="radio" name="tabs" id="tab1" checked="checked" />
                <label for="tab1"><span class="material-icons">Description</span></label>
                <div class="tab">
                  <p>Welcome to WellnessCopper, where we blend the richness of ancient wellness traditions with the elegance of modern living. As specialists in copper bottle offerings, we provide not just a vessel for hydration but a conduit to potential health benefits. Crafted with precision and care, our bottles are a harmonious fusion of artistry and functionality. Embrace the warmth of copper, promote well-being, and join a community that values tradition, sustainability, and a mindful lifestyle. Elevate your hydration experience with WellnessCopper – a journey of health, beauty, and timeless elegance. </p>
                </div>

                <input type="radio" name="tabs" id="tab2" />
                <label for="tab2"><span class="material-icons">Specification</span></label>
                <div class="tab">
                  <table>
                    <tr>
                      <td>Box Content:</td>
                      <td>1xBottle</td>
                    </tr>
                    <tr>
                      <td>Dimension (inches):</td>
                      <td>L-11 x W-2.5 x H-2.5</td>
                    </tr>
                    <tr>
                      <td>Product Weight:</td>
                      <td>240-300 grams</td>
                    </tr>
                    <tr>
                      <td>Capacity:</td>
                      <td>950 ml</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  )
}

export default DescriptionBox
