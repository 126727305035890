import React from 'react'
import './SlideBanner.css'
// import { Link } from 'react-router-dom'
// import bottel from '../assets/wsbottle.png'

const SlideBanner = () => {
  return (
    <>
      <div className="banner-container">
        <div className="container">
            <div className="content">
                {/* <h2>Experience the Power of Copper</h2>
                <p>Embrace a healthier lifestyle with our premium copper bottles. Enjoy the natural benefits of copper-infused hydration.</p>
                <Link to='shop'><button>Shop Now</button></Link> */}
            </div>
            {/* <div className="image">
                <img src={bottel} alt="" />
            </div> */}
        </div>
      </div>
    </>
  )
}

export default SlideBanner
