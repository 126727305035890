// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulkenquiry{
    height: 2000px;
}

.bulkenquiry iframe{
    width: 640px;
    height: 1950px
}

@media screen and (max-width: 650px){
    .bulkenquiry{
        height: 2200px;
    }
    .bulkenquiry iframe{
        width: 100vw;
        height: 2100px
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/CSS/BulkEnquiry.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;IACA;QACI,YAAY;QACZ;IACJ;AACJ","sourcesContent":[".bulkenquiry{\n    height: 2000px;\n}\n\n.bulkenquiry iframe{\n    width: 640px;\n    height: 1950px\n}\n\n@media screen and (max-width: 650px){\n    .bulkenquiry{\n        height: 2200px;\n    }\n    .bulkenquiry iframe{\n        width: 100vw;\n        height: 2100px\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
