import React from 'react'
import TabList from '../components/tabList/TabList'
import './CSS/MyAccount.css'

const MyAccount = () => {
  return (
    <div className='myAccount'>
        <div className="container">
            <h2>My Account</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati commodi assumenda quibusdam eaque officia voluptas labore sit suscipit sapiente error.</p>
        </div>
        <div className='container'>
            <TabList/>
        </div>
    </div>
  )
}

export default MyAccount
