import React from 'react'
import { Link } from 'react-router-dom'
import '../about/About.css'

const About = () => {
  return (
    <div>
        <div className="container about">
            <div className="content">
                {/* <span>About Us</span> */}
                <h3>Elevating Wellness with Copper Elegance</h3>
                <p>At Wellness Copper, we believe in the power of tradition and the enduring allure of natural remedies. Founded with a vision to merge ancient wisdom with contemporary lifestyle, we specialize in crafting exquisite copper bottles that epitomize elegance and wellness.</p> <br />

                <p>Driven by a passion for holistic health, we draw inspiration from the timeless practices of Ayurveda and other ancient traditions, where copper has long been revered for its therapeutic properties. Our mission is to reintroduce this age-old treasure to the modern world, offering a luxurious yet practical solution for those seeking to elevate their well-being.</p> <br />

                <p>With meticulous attention to detail, each of our copper bottles is meticulously handcrafted by skilled artisans, ensuring unparalleled quality and craftsmanship. From classic designs to intricate motifs, our collection caters to diverse tastes, reflecting the rich heritage and cultural diversity of copper's legacy.</p> <br />

                <p>But our commitment to wellness extends beyond mere aesthetics. We are dedicated to promoting sustainability and environmental consciousness, which is why our bottles are not only timeless in design but also eco-friendly in essence. By choosing our copper bottles, you're not just investing in your health but also contributing to a greener, more sustainable future.</p>  <br />

                <p>At Wellness Copper, we envision a world where wellness is not just a trend but a way of life—a journey guided by the wisdom of the past and the promise of a healthier tomorrow. Join us in embracing the elegance of copper and unlocking the transformative power of tradition. Together, let's raise a toast to a life of vitality, balance, and everlasting wellness.</p>
                <Link to='/about'><button onClick={window.scrollTo(0,0)}>Learn more</button></Link>
            </div>
            <div className="image">
                <div className="img-box">

                </div>

            </div>
        </div>
      
    </div>
  )
}

export default About
