import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/PrivacyPolicies.css'

const PrivacyPolicies = () => {
    return (
        <div className='policies'>
            <div className="container">
                <h1>Privacy Policies</h1>
                <section className='text-centre'>
                    <h2>Thank you for choosing Wellness Copper.</h2>
                    <p>This Privacy Policy outlines how we collect, use, and protect your personal information. We are committed to ensuring the privacy and security of your data.</p>
                </section>

                <section>
                    <h2>1. Information We Collect:</h2>
                    <p>1.1. Personal Information: When you make a purchase, we collect information such as your name, shipping address, email address, and payment details to process and fulfill your order.</p>
                    <p>1.2. Account Information: If you create an account with us, we may collect and store your username, password, and other account-related information.</p>
                    <p>1.3. Communication Data: We may collect data when you contact us via email, chat, or other communication channels, including the content of your communication.</p>
                    <p>1.4. Device and Usage Information: We collect information about your device and how you interact with our website, including IP addresses, browser type, and pages visited.</p>
                </section>

                <section>
                    <h2>2. How We Use Your Information:</h2>
                    <p>2.1. Order Fulfillment: We use your personal information to process and fulfill your orders, including shipping, invoicing, and customer service.</p>
                    <p>2.2. Account Management: If you create an account, we use your information to manage your account, including order history and preferences.</p>
                    <p>2.3. Communication: We may use your contact information to communicate with you about your orders, promotions, and updates if you've subscribed to our newsletter.</p>
                    <p>2.4. Analytics and Improvement: We use data to analyze website performance, improve our products and services, and enhance the user experience.</p>
                </section>

                <section>
                    <h2>3. Data Security:</h2>
                    <p>3.1. We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>
                </section>

                <section>
                    <h2>4. Sharing Your Information:</h2>
                    <p>4.1. We do not sell, trade, or otherwise transfer your personal information to outside parties. Your information is only shared with trusted third parties who assist us in operating our website, conducting our business, or serving you.</p>
                </section>

                <section>
                    <h2>5. Cookies:</h2>
                    <p>5.1. We use cookies to enhance your experience on our website. You can choose to disable cookies through your browser settings, but this may affect the functionality of the site.</p>
                </section>

                <section>
                    <h2>6. Your Rights:</h2>
                    <p>6.1. You have the right to access, correct, or delete your personal information. You can also opt-out of promotional communications at any time.</p>
                </section>

                <section>
                    <h2>7. Changes to this Policy:</h2>
                    <p>7.1. We reserve the right to modify this Privacy Policy at any time. Changes and clarifications will take effect immediately upon their posting on the website.</p>
                </section>
            </div>

            <section>
                <p>For any further questions regarding our Privacy Policy, please contact us at <Link to={'mailto:help@wellnesscopper.com'}>help@wellnesscopper.com</Link>.</p>
                <p>Thank you for choosing Wellness Copper.</p> <br />
                <p>
                    <b>AVONTIK INTERNATIONAL LLP</b> <br />
                    <b>INDIA</b> <br />
                    <Link to={'mailto:help@wellnesscopper.com'}>help@wellnesscopper.com</Link> <br />
                    <Link to={'tel:+91 8827512215'}>+91 8827512215</Link>
                </p>
            </section>
        </div>
    )
}

export default PrivacyPolicies
