// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --bg-light: #f4f9fd;
  --text-dark: #010101;
  --text-light: #4f4f50;
  --text-extra-light: #afafaf;
  --text-danger: rgb(217, 54, 54);
  --highlight-color: #bb8652;
  --main-color: #0aad0a;
}

*{
  margin: 0;
  padding: 0;
}

html{
  font-family: 'Quicksand', sans-serif !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-danger{
  color: var(--text-danger);
  font-size: 12px;
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px){

}

/*  */
@media only screen and (max-width: 992px){
    
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px){
    
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px){
    

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px){

}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,2BAA2B;EAC3B,+BAA+B;EAC/B,0BAA0B;EAC1B,qBAAqB;AACvB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;;AAGA,oEAAoE;AACpE;;AAEA;;AAEA,KAAK;AACL;;AAEA;;;AAGA,qDAAqD;AACrD;;AAEA;;;AAGA,oEAAoE;AACpE;;;AAGA;;AAEA,iDAAiD;AACjD;;AAEA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');\n\n:root{\n  --bg-light: #f4f9fd;\n  --text-dark: #010101;\n  --text-light: #4f4f50;\n  --text-extra-light: #afafaf;\n  --text-danger: rgb(217, 54, 54);\n  --highlight-color: #bb8652;\n  --main-color: #0aad0a;\n}\n\n*{\n  margin: 0;\n  padding: 0;\n}\n\nhtml{\n  font-family: 'Quicksand', sans-serif !important;\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.text-danger{\n  color: var(--text-danger);\n  font-size: 12px;\n}\n\n\n/* Extra large devices (large laptops and desktops, 1200px and up) */\n@media only screen and (min-width: 1200px){\n\n}\n\n/*  */\n@media only screen and (max-width: 992px){\n    \n}\n\n\n/* Medium devices (landscape tablets, 768px and up) */\n@media only screen and (max-width: 768px){\n    \n}\n\n\n/* Small devices (portrait tablets and large phones, 600px and up) */\n@media only screen and (max-width: 600px){\n    \n\n}\n\n/* Extra small devices (phones, 600px and down) */\n@media only screen and (max-width: 600px){\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
