import React from 'react'
import './Failure.css'
import error_icon from '../assets/error_red.png'

const Failure = () => {
  return (
    <div className='failure'>
        <h2>Something Went wrong!!</h2>
        <img src={error_icon} alt="" />

      
    </div>
  )
}

export default Failure
