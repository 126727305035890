import React, { useContext, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { ShopContext } from "../../context/ShopContext";
import logo from "../assets/wc-logo.png"

const Navbar = () => {
  const { getTotalCartAmount, getTotalCartItems } = useContext(ShopContext);
  const [navOpen, setNavOpen] = useState(false);
  // const {isLoggedIn} = useContext(ShopContext);

  return (
    <>
      <div className={navOpen ? "mobile-navbar open" : "mobile-navbar"}>
        <span className="close"><i className="ri-close-line" onClick={() => {
          setNavOpen(false);
        }}></i></span>
        <ul>
          <li><Link to='/' onClick={() => {
            setNavOpen(false);
          }}>Home</Link></li>
          <li><Link to='/shop' onClick={() => {
            setNavOpen(false);
          }}>All Products</Link></li>
          <li><Link to='/about'>About us</Link></li>
          <li><Link to='/bulk-enquiry'>Bulk Enquiry</Link></li>
          <li><Link to='/contact'>Contact us</Link></li>
        </ul>
      </div>
      <div className="header-nav">
        <div className="container">
          <div className="wrapper flexitem">
            <button className="trigger desktop-hide" onClick={() => {
              setNavOpen(true);
            }}>
              <span className="i ri-menu-2-line"></span>
            </button>
            <div className="left flexitem">
              <div className="logo">
                <Link className="logo-link" to='/'>
                  <div className="a">
                    {/* <span className="circle"></span>Wcopper */}
                    <img src={logo} alt="" width={100} />
                  </div>
                </Link>
              </div>
              <nav className="mobile-hide">
                <ul className="flexitem second-link">
                  <li className="active"><Link to='/'>Home</Link></li>
                  <li><Link to='/shop'>All Products</Link></li>
                  <li hidden>
                    <div className="a">
                      Categories
                      <div className="fly-item">
                        <span>New!</span>
                      </div>
                    </div>
                  </li>
                  <li><Link to='/bulk-enquiry'>Bulk Enquiry</Link></li>
                  <li><Link to='/about'>About</Link></li>
                  <li><Link to='/contact'>Contact</Link></li>
                </ul>
              </nav>
            </div>
            
            <div className="right">
              <ul className="flexitem second-link">
                <li className="mobile-hide" hidden={true}>
                  {/* <div className="a">
                    {isLoggedIn? <div className="icon-large"><i className="ri-account-circle-line"></i></div>: <Link to='/login'><button className="login-btn">Login</button></Link>}
                  </div> */}
                </li>
                <li>
                  <div className="iscart a">
                    <div className="icon-large">
                      <Link to='/cart'><i className="ri-shopping-cart-line"></i></Link>
                      <div className="fly-item">
                        <span className="item-number">{getTotalCartItems()}</span>
                      </div>
                    </div>
                    <div className="icon-text">
                      <Link to='/cart'>
                        <div className="mini-text">Total</div>
                        <div className="cart-total">₹{getTotalCartAmount()}</div>
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
