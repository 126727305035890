import React from 'react'
import './RelatedProducts.css'
import data_product from '../assets/data'
import Item2 from '../Item2/Item2'

const RelatedProducts = () => {
  return (
    <div className='relatedproducts'>
        <h1>Related Products</h1>
        <hr />
        <div className="relatedproducts-item">
            {data_product.map((item, i)=>{
                return <Item2 key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} stock={item.stock} rating={item.rating}/>
            })}
        </div>
      
    </div>
  )
}

export default RelatedProducts
