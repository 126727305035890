import React from 'react'
import './Popular.css'
// import data_products from '../assets/data'
import new_collection from '../assets/new_collections'
import Item2 from '../Item2/Item2'


const Popular = () => {
  return (
    <div className='popular container'>
      <h1>POPULAR PRODUCTS</h1>
      <hr />
      <div className="popular-item">
        {new_collection.map((item, i)=>{
            return <Item2 key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} category={item.category} rating={item.rating} stock={item.stock}/>
        })}
      </div>
    </div>
  )
}

export default Popular
