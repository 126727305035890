import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Home from './pages/Home'
import Product from './pages/Product';
import Cart from './pages/Cart';
// import LoginSignup from './pages/LoginSignup';
import ShopCategory from './pages/ShopCategory';
import men_banner from './components/assets/banner_mens.png'
import Footer from './components/footer/Footer';
import Success from './components/success/Success';
import Checkout from './components/checkout/Checkout';
import Failure from './components/failure/Failure';
import PrivacyPolicies from './pages/PrivacyPolicies';
import ReturnRefund from './pages/ReturnRefund';
import ShippingPolicies from './pages/ShippingPolicies';
import TermsOfService from './pages/TermsOfService';
import PaymentStatus from './pages/PaymentStatus';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import LoginOverlay from './pages/LoginOverlay';
import SignupOverlay from './pages/SignupOverlay';
import MyAccount from './pages/MyAccount';
import BulkEnquiry from './pages/BulkEnquiry';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/shop' element={<ShopCategory category="Copper Bottle" banner={men_banner}/>} />
          <Route path='/product' element={<Product/>} >
            <Route path=':productId' element={<Product/>}/>
          </Route>
          <Route path='/cart' element={<Cart/>} />
          <Route path='/login' element={<LoginOverlay/>} />
          <Route path='/signup' element={<SignupOverlay/>} />
          <Route path='/checkout' element={<Checkout/>} />
          <Route path='/check_status' element={<PaymentStatus/>} >
            <Route path=':merchantId' element={<PaymentStatus/>}/>
          </Route>
          <Route path='/success' element={<Success/>} />
          <Route path='/failure' element={<Failure/>} />
          <Route path='/privacy-policy' element={<PrivacyPolicies/>} />
          <Route path='/terms-of-service' element={<TermsOfService/>} />
          <Route path='/refund-policy' element={<ReturnRefund/>} />
          <Route path='/shipping-policy' element={<ShippingPolicies/>} />
          <Route path='/about' element={<AboutUs/>} />
          <Route path='/contact' element={<ContactUs/>} />
          <Route path='/myaccount' element={<MyAccount/>} />
          <Route path='/bulk-enquiry' element={<BulkEnquiry/>} />
        </Routes>
      <Footer/>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
