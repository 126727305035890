// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrum{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #5e5e5e;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/breadcrumbs/Breadcrum.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B","sourcesContent":[".breadcrum{\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    color: #5e5e5e;\n    font-size: 16px;\n    font-weight: 600;\n    text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
