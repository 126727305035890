import { useContext, useState } from "react";
import "./CSS/LoginOverlay.css";
import { ShopContext } from "../context/ShopContext";
import { Link } from "react-router-dom";


function LoginOverlay() {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const {login, accessToken} = useContext(ShopContext);

    if (accessToken!==undefined){
        window.location.href = '/';
    }

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    
    const handleSubmit = async(event) => {
        setLoading(!loading)
        event.preventDefault(); // Prevent default form submission
    
        try {
          const loginState = await login(formData); // Call the login function from context
          // Handle successful login (e.g., close the overlay)
          console.log("Login State is", loginState);
          if (loginState==="success"){
            setLoading(false);
            window.location.href = '/';
          }else{
            setLoading(false);
          }
        } catch (error) {
          // Handle login errors
          setLoading(false);
          console.error("Login error:", error);
        }
      };

    return (
        <>
            <div id="overlay">
                <div id="loginForm">
                    <button id="closeLogin">&times;</button>
                    <img src="https://www.wellnesscopper.com/static/media/wc-logo.e1beafa9cafbd214a659.png" alt="Wellness Copper Logo" id="logo" />
                    <h2>Welcome back</h2>
                    <form onSubmit={handleSubmit}>
                        <input type="email" name="email" placeholder="Email" onChange={handleInputChange} required />
                        <div className="password-container">
                            <input type={showPassword ? "text" : "password"} name="password" placeholder="Password" required id="password" onChange={handleInputChange} />
                            <button type="button" className="show-password" onClick={togglePassword}>
                                {showPassword ? (
                                    <span className="ri-eye-line"></span>
                                ) : (
                                    <span className="ri-eye-off-line"></span>
                                )}
                            </button>
                        </div>
                        <Link className="forgetPass" to=''>Forget Password?</Link>
                        <button type="submit">Login <span className={loading ? "btn-ring" : ""}></span></button>
                        <p className="signup">Don't have an account? <Link to='/signup'>Sign Up</Link></p>
                    </form>
                </div>
            </div>
        </>
    );
}

export default LoginOverlay;
