import React from 'react'
import './MyDetails.css'

const MyDetails = () => {
  return (
    <div className='myDetails'>
      <h2>My details</h2>
      <h3>Personal information</h3>
    </div>
  )
}

export default MyDetails
